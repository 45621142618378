.sidebar {
    width: 312px !important;
    min-width: 312px !important;
    border-right: unset !important;
    // overflow: hidden;

    @media (max-width: 1366px) {
        min-width: 250px !important;
        width: 250px !important;
    }

    &.ps-collapsed {
        width: 95px !important;
        min-width: 95px !important;

        @media (max-width: 1920px) and (max-height: 600px) {
            .logo-header {
                width: 95px !important;
            }
        }

        @media (max-width: 1366px) {
            width: 80px !important;
            min-width: 80px !important;

            .logo-header {
                width: 80px !important;
            }
        }

        .bottom-menu {
            .css-vj11vy {
                .switch-anchor {
                    .css-1bdadyq {
                        transition: all 0.5s;
                        padding: 0 17px;

                        @media (max-width: 1366px) {
                            padding: 0 10px;
                        }
                    }
                }
            }
        }

        .admin-sidebar-title {
            max-width: 56px;
            overflow: hidden;
            transition: all 0.3s;
            @media (max-width: 1366px) {
                max-width: 46px;
            }
        }
    }

    &.ps-toggled {
        width: 250px !important;
        left: 0 !important;
    }

    .ps-sidebar-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100vh;
        max-height: 100%;
        overflow: hidden;
        padding: 20px 0 50px 0;
        background-color: #0a5f59;
        width: 100%;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (max-width: 1920px) and (max-height: 600px) {
            overflow: auto;
            padding-top: 0;

            .bottom-menu {
                margin-top: 100px;
            }

            .logo-header {
                position: fixed;
                z-index: 99;
                background-color: #0a5f59;

                width: 312px;
                padding: 20px 0;
            }
        }

        // @media (max-width: 1366px) {
        //     padding: 20px 0;
        // }

        @media (max-width: 1366px) and (min-height: 900px) {
            padding-top: 20px;
        }
    }

    .top-menu,
    .bottom-menu {
        width: 100%;
        ul {
            li {
                .ps-menu-button {
                    &:hover {
                        background: transparent;
                    }
                    .icon-text {
                        &:hover {
                            .text {
                                color: #fff;
                            }
                            .menu-icon-active {
                                display: block;
                            }
                            .menu-icon {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        .css-vj11vy {
            .logo-header {
                padding-bottom: 30px;

                @media (max-width: 1600px) and (max-height: 900px) {
                    // padding: 25px 0;
                }

                @media (max-width: 1366px) {
                    width: 250px;
                }

                @media (max-width: 1366px) and (max-height: 620px) {
                    padding: 15px 0;
                }

                .css-1bdadyq {
                    padding: 0 30px;
                    &:hover {
                        background-color: wheat;
                    }
                    @media (max-width: 1366px) {
                        padding-right: 15px;
                        padding: 0 20px;
                    }

                    @media (max-width: 992px) {
                        padding: 0 20px;
                    }
                }
            }
        }

        .logo-wrapper {
            @media (min-width: 1367px) {
                padding-top: 30px;
            }
            img {
                width: 80%;
                padding-bottom: 4px;

                @media (max-width: 992px) {
                    pointer-events: none;
                }
            }

            .toggle {
                margin-left: auto;
                cursor: pointer;

                img {
                    @media (max-width: 992px) {
                        display: none;
                    }
                }
            }
        }

        .menu-item {
            .icon-text {
                display: flex;
                align-items: center;
                margin-top: 20px;
                white-space: pre-wrap !important;

                img {
                    width: 22px;
                    height: 22px;
                }

                .text {
                    margin-left: 25px;
                    font-family: "Sora";
                    font-weight: 500;
                    color: #83cdc8;
                    font-size: 15px;

                    @media (max-width: 1366px) {
                        margin-left: 20px;
                        font-size: 14px;
                    }
                }

                .menu-icon-active {
                    display: none;

                    .text {
                        font-size: 15px;
                    }
                }

                &.active {
                    .text {
                        color: #ffffff;
                    }

                    .menu-icon {
                        display: none;
                    }

                    .menu-icon-active {
                        display: block;
                    }
                }
            }

            .css-1bdadyq {
                padding-left: 35px;
                padding-bottom: 20px;

                @media (max-width: 1366px) {
                    height: 45px;
                    padding-left: 25px;
                }
            }
        }
    }

    .top-menu {
        .menu-item {
            @media (max-width: 1920px) and (max-height: 600px) {
                &:nth-of-type(2) {
                    margin-top: 100px;
                }
            }

            @media (max-width: 1366px) and (max-height: 550px) {
                &:nth-of-type(2) {
                    margin-top: 80px;
                }
            }
        }

        .admin-sidebar-title {
            font-weight: 700;
            font-size: 24px;
            font-family: "Sora";
            padding-left: 38px;
            @media (max-width: 1366px) {
                padding-left: 28px;
            }
        }
    }

    .bottom-menu {
        .css-vj11vy {
            .switch-anchor {
                .css-1bdadyq {
                    padding: 0 35px;
                    transition: all 0.5s;
                    cursor: pointer;

                    @media (max-width: 1366px) {
                        padding: 0 25px;
                    }

                    &:hover {
                        color: #2b292d;
                    }
                }

                .react-switch-bg {
                    width: 50px !important;
                    height: 26px !important;
                }

                .react-switch-handle {
                    height: 20px !important;
                    width: 20px !important;
                    transform: translateX(4px);
                    top: 3px !important;
                }
            }
            .switch-main {
                display: flex;
                align-items: center;
                gap: 10px;
                .switch-label {
                    display: flex;
                    align-items: center;

                    .label {
                        margin-left: 20px;
                        font-weight: 600;

                        @media (max-width: 1366px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .css-1bdadyq:hover {
        background-color: #1f7e77;
    }
}


.custom-project-site-sub-menu{
   
    .ps-menu-button{
        height: auto !important;
        margin-top:20px;
        margin-bottom: 5px;
        .ps-submenu-expand-icon span{
            color: #fff;
        }
    }
    .ps-menu-icon {
        margin-left: 0px;
        width: 22px;
        height: 22px;
        min-width: 22px;
    }
    .ps-menu-label
    {
        font-size: 14px;
        font-weight: 600;
        color: #83cdc8;
        margin-left: 15px;
    }
   
    .ps-submenu-content{
        background-color: #0f857c;
        
        padding-left: 0px;
        .ps-menuitem-root:first-child{
            padding-top: 13px;
        }
        .ps-menuitem-root:last-child{
            padding-bottom: 13px;
        }
        .ps-menuitem-root{
            padding-bottom: 4px;
            padding-top: 4px;
            color: #fff;
        }
        .ps-submenu-root{
        
        }
        .ps-menuitem-root a:hover{color: #fff;}
        .ps-menu-button{
            height: auto !important;
            margin-top: 7px;
            margin-bottom: 7px;
            margin-left: 15px;
            .ps-menu-label{
                color: #fff !important;
            }
        }
    }
}