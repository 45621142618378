.content-section {
    &.materialtracting-section {
        padding: 38px 35px 38px 41px !important;

        @media (max-width: 767px) {
            padding: 10px 15px 10px 15px !important;
        }

        .headingStyle {
            display: flex;
            gap: 10px;
            align-items: center;
            @media (max-width: 1100px) {
                flex-wrap: wrap;
            }

            margin-bottom: 15px;
            @media (max-width: 767px) {
                margin-bottom: 10px;
            }
            .filter-wrapper {
                height: auto;
            }
            span {
                // display: flex;
                gap: 10px;
                flex-grow: 1;
                align-items: baseline;

                @media (max-width: 767px) {
                    margin-bottom: 10px;
                    flex-basis: 100%;
                }
            }

            form {
                .search-field {
                    max-width: unset;
                }

                @media (max-width: 767px) {
                    flex-grow: 1;
                }
            }

            h1 {
                font-weight: 600;
                width: 100%;
                font-size: 24px;
                line-height: 30px;
                letter-spacing: -0.02em;
                color: #333333;
                margin: 0;
            }
        }

        .bodyScroll {
            max-height: calc(100vh - 245px);
            top: 0px;

            @media (max-width: 767px) {
                max-height: calc(100vh - 238px);
                top: 0;
            }

            @media (max-width: 575px) {
                max-height: calc(100vh - 230px);
                top: 0;
            }

            @media (max-width: 402px) {
                max-height: calc(100vh - 280px);
                top: 0;
            }

            .customCollapse {
                background: #fff;
                margin-bottom: 24px;
                border: 1px solid #d1cfcb;
                border-radius: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                .collapse-Header {
                    padding: 20px;
                    border-bottom: 1px solid #d1cfcb;

                    h2 {
                        color: #333;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: -0.4px;
                        margin: 0;
                        text-transform: capitalize;

                        span {
                            font-weight: 300;
                        }
                    }

                    .aes-btn {
                        text-transform: capitalize;
                    }
                }

                .profile-info {
                    padding: 12px 20px;
                    border-bottom: 1px solid #d1cfcb;

                    h3.title {
                        color: #333;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: -0.15px;
                        margin: 0;

                        span {
                            font-weight: 300;
                        }
                    }
                }

                .truckId {
                    padding: 20px;

                    h4 {
                        color: #333;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: -0.15px;
                        margin: 0;
                    }

                    h5 {
                        color: #333;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 20px;
                        /* 133.333% */
                        letter-spacing: -0.15px;
                        margin: 0;
                    }

                    a {
                        color: #0a5f59;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        /* 166.667% */
                        letter-spacing: -0.12px;
                        text-decoration-line: underline;
                    }
                }

                .ant-collapse-item {
                    border-bottom: 0px solid;

                    .ant-collapse-header {
                        align-items: center;
                        padding-left: 20px;
                        padding-right: 20px;
                    }

                    .ant-collapse-content {
                        border-top: 1px solid #d1cfcb;
                        padding: 0;

                        .ant-collapse-content-box {
                            padding: 0;
                        }
                    }
                }
            }

            .nodatafound {
                text-align: center;
                padding: 10rem 0;
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }
}
