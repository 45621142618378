.content-section {
    &.team-members-section {
        padding-left: 0 !important;
        min-height: unset;
        .team-members-content-section {
            padding: 0 40px 0 42px;
            @media (max-width: 760px) {
                padding: 0 20px 0 20px;
            }
            .heading-my-team {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 26px;
                @media (max-width: 776px) {
                    display: block !important;
                }
                h1 {
                    margin-bottom: 0;
                    font-size: 24px;
                    font-weight: 600;
                    @media (max-width: 760px) {
                        font-size: 20px !important;
                        margin-bottom: 20px !important;
                    }
                }
                .RequestSoilDeposit {
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 30px;
                    text-align: center;
                    letter-spacing: -0.02em;
                    background: #0a5f59;
                    border-radius: 4px;
                    color: #ffffff;
                    border: 1px solid #0a5f59;
                    padding: 9px 25px;
                }
                .button-wrapper {
                    .aes-btn {
                        &.green-btn {
                            min-width: 147px;
                            height: 47px;
                            @media (max-width: 760px) {
                                min-width: 137px;
                            }
                        }
                    }
                }
            }
        }
    }
}
