.content-section {
    font-family: "Sora";

    &.homeScreen {
        font-family: "Sora";
        min-height: 100vh;
        .right-content {
            // overflow-y: auto;
            // height: 100vh;

            .homelogo {
                padding: 35px 46px;

                @media (max-width: 1100px) {
                    padding: 35px;
                }

                @media (max-width: 576px) {
                    padding: 35px 25px;
                }

                @media (max-width: 450px) {
                    padding: 35px 15px;
                }

                .logo {
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    .loginbuton {
                        display: none;
                        @media (max-width: 992px) {
                            display: block;
                            .aes-btn {
                                min-width: 100px;
                            }
                        }
                    }

                    img {
                        min-width: 127px;
                        min-height: 50px;
                    }
                }
            }

            .content-details {
                overflow-y: auto;
                margin-top: 14%;
                margin-left: 50px;
                max-height: calc(100vh - 170px);
                overflow: auto;

                @media (max-width: 1100px) {
                    margin-left: 0;
                }

                @media (max-width: 992px) {
                    margin-top: 6%;
                }

                .heading2 {
                    h3 {
                        font-size: 2.875vw;
                        color: #303030;
                        font-weight: 500;

                        @media (max-width: 1100px) {
                            font-size: 30px;
                        }

                        @media (max-width: 992px) {
                            font-size: 35px;
                            text-align: center;
                        }

                        @media (max-width: 576px) {
                            font-size: 28px;
                            text-align: center;
                        }

                        @media (max-width: 450px) {
                            font-size: 24px;
                            text-align: center;
                        }

                        @media (max-width: 400px) {
                            font-size: 20px;
                            text-align: center;
                        }
                    }
                }

                .sub-text {
                    display: flex;
                    margin-top: 23px;

                    @media (max-width: 1100px) {
                        padding-right: 40px;
                    }

                    @media (max-width: 576px) {
                        padding-right: 15px;
                    }

                    .circle {
                        background-color: #1b8179;
                        height: 45px;
                        min-width: 45px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        color: white;
                        font-size: 22px;
                        font-weight: 500;
                        margin-right: 20px;
                        margin-top: 8px;

                        @media (max-width: 1100px) {
                            font-size: 18px;
                            height: 40px;
                            min-width: 40px;
                            margin-right: 15px;
                        }

                        @media (max-width: 576px) {
                            font-size: 16px;
                            height: 35px;
                            min-width: 35px;
                            margin-right: 10px;
                        }
                    }

                    .text-one {
                        h6 {
                            // margin: 0;
                            margin-top: 7px;
                            margin-bottom: 0px;
                            font-size: 20px;
                            font-weight: 500;
                            color: #303030;

                            @media (max-width: 1100px) {
                                font-size: 18px;
                            }

                            @media (max-width: 576px) {
                                font-size: 16px;
                            }
                        }

                        p {
                            margin-top: 5px;
                            margin-bottom: 0px;
                            font-size: 16px;
                            font-weight: 300;
                            color: #707070;
                            line-height: 24px;

                            @media (max-width: 1100px) {
                                font-size: 14px;
                                line-height: 20px;
                            }

                            @media (max-width: 576px) {
                                font-size: 13px;
                                line-height: 16px;
                            }
                        }
                    }
                }

                .button-wrapper {
                    margin-top: 44px;
                    .aes-btn {
                        min-width: 180px;
                        min-height: 48px;
                    }
                }
            }
        }
        .img-right {
            display: block;
            @media (max-width: 992px) {
                display: none;
            }
            .left {
                .aes-btn {
                    min-width: 127px;
                    position: absolute;
                    top: 35px;
                    right: 35px;
                }
            }
        }
    }
}
.left {
    .aes-btn {
        min-width: 127px;
        position: absolute;
        top: 35px;
        right: 35px;
    }
}
