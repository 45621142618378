.content {
    font-family: "Sora";
    background-color: #f2f1ec;
    padding: 35px 20px 0px 41px;
    overflow: auto;
    // padding-bottom: 100px;

    &.Schedule {
        height: calc(100vh - 80px);
        padding-bottom: 20px;

        .headerFilter {
            display: flex;
            // justify-content: space-between;

            align-items: baseline;
            gap: 10px;
            .filter {
                width: 175px !important;
                padding: 12px 10px;
                .button-filter {
                    padding: 0;
                }
                .date {
                    padding: 0;
                }
            }
            .filter-wrapper {
                width: 205px;
                .filter {
                    width: 100% !important;
                }
            }
            @media (max-width: 576px) {
                display: block !important;
            }
            h1 {
                font-weight: 600;
                font-size: 24px;
                line-height: 50px;
                letter-spacing: -0.02em;
                color: #333333;
            }
            .header {
                display: flex;
                // justify-content: space-between;
                align-items: baseline;
                width: -webkit-fill-available;

                .filter {
                    .button-filter {
                        padding: 13px 16px;
                    }
                    p {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        letter-spacing: -0.01em;
                        color: #333333;
                        span {
                            padding: 0 5px;
                            color: #898989;
                        }
                    }
                    .date {
                        padding: 13px 16px;
                    }
                }
            }
        }

        .card {
            padding: 6px 0px 20px 0px;
            margin-top: 20px;
            border: unset;
            .mainrow {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                border-bottom: 1px solid #dcd9d5;
                p {
                    font-size: 14px;
                    font-weight: 300;
                    color: #0a5f59;
                    margin: 0;
                }

                .date {
                    background-color: #e4f4f3;
                    padding: 10px;
                    border-radius: 4px;
                }
                .btns {
                    display: flex;
                    align-items: center;
                    gap: 30px;
                }
            }

            .right-text {
                padding-top: 16px;
                padding-bottom: 16px;
                border-bottom: 2px solid whitesmoke;

                p {
                    margin-bottom: 0px;
                    padding-left: 20px;
                    font-size: 15px;
                    font-weight: 600;
                    color: #333333;

                    span {
                        margin-bottom: 0px;
                        padding-left: 5px;
                        font-size: 13px;
                        font-weight: 500;
                        color: gray;
                    }
                }
            }
        }
    }
}
