.bg-section-contact {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-image: url("../images/site-engineer.png");
}

.bg-section-contact::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.contact-content-section {
    position: relative;
    z-index: 1;
}

.heading-Contact2 {
    font-size: 40px;
    font-weight: 500;
    color: #ffff;
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-family: "Sora";
}

@media (max-width: 767px) {
    .heading-Contact {
        font-size: 30px;
    }
}

.main-para {
    margin: 0px;
    font-size: 19px;
    font-weight: 300;
    color: whitesmoke;
    text-align: center;
    opacity: 0.8;
    font-family: "Sora";
}

@media (max-width: 767px) {
    .main-para {
        font-size: 16px;
        max-width: 80%;
        margin: auto !important;
    }
}

.heading-text {
    margin: 0px;
    font-size: 22px;
    font-weight: 500;
    color: #32302e;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: "Sora";
}

.heading-from {
    font-size: 15px;
    font-weight: 600;
    color: #32302e;
    margin-bottom: 12px;
    font-family: "Sora";
}

.text-from {
    font-size: 15px;
    font-weight: 300;
    font-family: "Sora";
}

.input {
    border: 0;
    background-color: #ffff;
    height: 50px;
    width: 50%;
    padding: 10px;
    padding-right: 10px;
    padding-left: 23px;
    font-family: "Sora";

    padding-top: 10px;
    font-weight: 300;
    color: #272525;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #272525;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #272525;
}

.main-div-input {
    display: flex;
    align-items: center;
    gap: 20px;
}

@media (max-width: 425px) {
    .main-div-input {
        flex-direction: column;
        width: 100%;
    }

    .input {
        width: 100%;
    }
}

.textarea {
    border: 0;
    width: 100%;
    margin-top: 20px;
    height: 110px;
    /* padding: 10px */
    padding-right: 30px;
    padding-left: 23px;
    padding-top: 10px;
    resize: none;
    font-family: "Sora";
}

.btn2 {
    width: 148px;
    height: 50px;
    background-color: #89dcb4;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    border: none;
    font-family: "Sora";
}

.btn button :focus {
    background-color: red;
}

input:hover {
    background-color: white;
    border-color: unset;
}

input:focus,
select:focus,
textarea:focus {
    outline: none;
    background-color: white;
}

input::placeholder,
textarea::placeholder {
    color: #272525;
    font-weight: 400;
}

@media (max-width: 425px) {
    input::placeholder,
    textarea::placeholder {
        font-size: 14px;
    }
}

.form-section {
    padding: 104px 0;
    background-color: #f5f1ea;
}
.errer-text-message {
    color: red;
    margin-right: 10%;

    @media (max-width: 540px) {
        display: none;
    }
}
.errer-text {
    color: red;

    @media (max-width: 540px) {
        display: none;
    }
}
