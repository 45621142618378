.homepage-section {
    display: flex;
    width: 100%;
    overflow: hidden;

    .main-section {
        width: 100%;
        // max-height: 100vh;
        // overflow: auto;
        min-width: calc(100% - 312px);
        .content-section {
            padding: 38px 0 38px 15px;
            overflow: auto;
            max-height: calc(100vh - 80px);
            @media (max-width: 1280px){
                padding-left: 10px;
            }
            @media (max-width: 992px){
                max-height: calc(100vh - 80px);
            }
            @media (max-width: 576px){
                padding-left: 0;
            }
            &::-webkit-scrollbar{
                // display: none;
            }
            .home-card-wrapper {
                width: 100%;
                margin-bottom: 50px;
                @media (max-width: 1024px){
                    margin-bottom: 0;
                }

                h2 {
                    font-weight: 700;
                    font-size: 24px;
                    color: #2B292D;
                    margin-bottom: 30px;
                    @media (max-width: 1366px){
                        font-size: 20px;
                        margin-bottom: 20px;
                    }
                    @media (max-width: 1024px){
                        margin: 20px 0;
                    }

                    @media (max-width: 1024px){
                        font-size: 18px;
                    }
                }

                .home-card-row {
                    flex-wrap: nowrap;
                    overflow-x: auto;
                  &::-webkit-scrollbar {
                        display: none;
                    }
                    .no-data{
                        text-align: center;
                    }

                    .card-data {
                        flex-basis: 14.8%;
                        max-width: 185px;
                        @media (min-width:1600px){
                            max-width: 210px;
                        }
                        @media (min-width:1680px){
                            max-width: 240px;
                        }
                        @media (min-width:1920px){
                            max-width: 350px;
                        }
                        @media (min-width:2100px){
                            max-width: 400px;
                        }
                        @media (max-width: 1366px){
                            max-width: 200px;
                        }
                        @media (max-width: 1280px){
                            flex-basis: 18%;
                        }
                        @media (max-width: 1024px){
                            flex-basis: 23%;
                            max-width: 100%;
                        }
                        @media (max-width: 768px){
                            flex-basis: 27%;
                        }
                        @media (max-width: 576px){
                            flex-basis: 37%;
                        }
                        @media (max-width: 470px){
                            flex-basis: 45%;
                        }
                        @media (max-width: 400px){
                            flex-basis: 55%;
                        }
                    }

                    .slider-card {
                        background-color: #EFEFEF;
                        width: 100%;
                        @media (max-width:1440px) and (max-height:900px){
                           height: 191px;
                           max-width: 151px;
                        }

                        @media (max-width: 768px){
                            height: 100%;
                            max-width: 100%;
                        }
                        @media (min-width:1600px){
                            max-width: 200px;
                            height: 240px;
                        }
                        @media (min-width:1920px){
                            max-width: 270px;
                            height: 310px;
                        }
                        @media (min-width:2100px){
                            max-width: 310px;
                            height: 370px;
                        }

                        img {
                            opacity: 1;
                        }
                    }
                }
                .book-detail-card-wrapper {
                    @media (max-width: 1280px){
                        flex-basis: 30%;
                    }
                    @media (max-width: 1024px){
                        flex-basis: 35%;
                    }
                    @media (max-width: 768px){
                        flex-basis: 40%;
                    }
                    @media (max-width: 576px){
                        flex-basis: 55%;
                    }
                    @media (max-width: 440px){
                        flex-basis: 80%;
                    }
                }
            }
        }
    }
    .toggle-sidebar-icon{
        display: none;
        @media (max-width: 992px){
            display: block;
        display: none;

            min-height: 100vh;
            background-color: #EFEFEF;
            width: 50px;
            text-align: center;
            padding-top: 30px;
            border-radius: 0 10px 10px 0;
        }
        @media (max-width: 768px){
            width: 40px;
            img{
                height: auto;
                width: 25px;
        display: none;

            }
        }
        @media (max-width: 576px){
            padding-top: 20px;
        display: none;

        }
    }
}