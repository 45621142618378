.UploadDocoment-modal {
    width: 332px;

    .modal-body {
        width: fit-content !important;
        // padding: 20px;
    }

    .modal-div {
        padding: 20px;

        // padding: 40px;
        // align-self: center;
        h4 {
            color: #262626;
            font-family: "Sora";
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.24px;
        }

        h2 {
            color: #333;
            font-family: "Sora";
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            /* 133.333% */
            letter-spacing: -0.15px;
            margin-top: 20px;
        }

        h4 {
            color: #7b7b7b;
            font-family: "Sora";
            font-size: 15px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
            /* 133.333% */
            letter-spacing: -0.15px;
        }

        .tile-input {
            // width: 337.111px;
            width: 100%;
            height: 48px;
            flex-shrink: 0;
            border-radius: 4px;
            background-color: #f2f0ec !important;
            align-self: center;

            // margin-bottom: 22px;
            &.font {
                color: #333;

                font-family: "Sora";
                font-size: 18px !important;

                font-weight: 600;
                line-height: 50px;
                /* 277.778% */
                letter-spacing: -0.36px;
            }
        }

        .Report-needed {
            width: 100%;
            // height: 48px;
            flex-shrink: 0;
            border-radius: 4px;
            background-color: #f2f0ec !important;
            align-self: center;
            height: 100px !important;
        }

        .uplod-file {
            // width: 338.634px;
            height: 168.441px;
            flex-shrink: 0;
            border-radius: 4px;
            border: 1px dashed #c7c5c1;
            background: #f2f0ec;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &.border {
                border: 1px solid #c7c5c1 !important;
            }
        }

        .attch {
            color: #0a5f59;
            text-align: center;
            font-family: "Sora";
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            /* 146.667% */
            letter-spacing: -0.3px;
            text-decoration-line: underline;
        }

        .attch-div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .pdfupload2 {
            width: 29.837px;
            height: 37.296px;
            flex-shrink: 0;
            margin-bottom: 19px;
        }

        .btn-wraper {
            // background-color: #262626;
            margin-top: 20px;

            .aes-btn {
                width: 100%;
                height: 42px;
            }
        }
    }

    .ant-select,
    .ant-select-selector {
        height: 100% !important;
        align-items: center;
        border: none !important;
        border-radius: 8px;
        background-color: #f2f0ec !important;
    }

    .text-area-field textarea {
        background-color: #f2f0ec !important;
    }

    .ant-upload-wrapper .ant-upload-drag {
        background-color: unset !important;
        border: unset !important;
    }
    .ant-upload-drag .ant-upload {
        padding: 0 !important;
    }
}
