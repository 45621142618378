.accountRequestContainer {
    padding: 38px 35px 38px 41px !important;
    // @media (max-width: 767px) {
    //     padding: 10px 15px 10px 15px !important;
    // }

    .headingStyle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            font-weight: 600;
            width: 100%;
            font-size: 24px;
            line-height: 50px;
            letter-spacing: -0.02em;
            color: #333333;
        }

        .button-filter {
            display: flex;
            background: #E0DED9;
            border: 1px solid #CBC9C6;
            border-radius: 5px;
            padding: 14px 20px 14px 16px;
            gap: 10px;
            cursor: pointer;

            p {
                margin-bottom: 0;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.01em;
                color: #333333;
                white-space: noWrap;

                span {
                    color: #898989;
                    padding-left: 5px;
                }

            }
        }
    }

    .rejectBtn {
        padding: 9px 30px;
        border-radius: 4px;
        border: 1px solid #0A5F59;
        background-color: transparent;
        height: auto;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        color: #0A5F59;

        &:hover {
            color: #0A5F59;
            border: 1px solid #0A5F59;
        }
    }

    .approveBtn {
        padding: 9px 30px;
        border-radius: 4px;
        border: 0px solid;
        background: #F6D218;
        height: auto;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        color: #1E1E1E;
        margin: 0px 10px;

        &:hover {
            color: #1E1E1E;
        }
    }

    .accountRequestTable {
        width: 100%;
        overflow: auto;
        max-height: calc(100vh - 218px);

        .companyName {
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            margin: 0px;
            color: #333333;
        }

        .emailStyle {
            font-size: 15px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
            margin: 0px;
            color: #0A5F59;
            max-width: 85%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .commonStyle {
            font-size: 15px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
            color: #7B7B7B;
            margin: 0px;
        }
    }
}