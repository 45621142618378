.board-select-wrapper {
    height: 47px;
    width: 100%;

    @media (max-width: 1366px) {
        height: 40px;
    }
    @media (max-width: 576px) {
        height: 35px;
    }

    .select-label {
        display: block;
    }
    .ant-select-selector input {
        margin-top: 5px !important;
    }

    .ant-select,
    .ant-select-selector {
        height: 100% !important;
        align-items: center;
        border: none !important;
        border-radius: 8px;
        background-color: white !important;
    }

    .ant-select {
        max-width: 100% !important;
    }

    .ant-select-selection-item {
        position: relative;
        &::before {
            content: "";
            left: 6px;
            top: 4px;
            position: absolute;
            // background-image: url('../../assets/images/role.svg');
            background-position: center;
            background-size: cover;
            height: 21px;
            width: 21px;
            z-index: 999;
        }
    }
    .ant-select-item {
        margin-bottom: 5px;
    }
    .ant-select-selection-search {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            background-image: url("../../assets/images/chevron-down.svg");
            background-position: center;
            background-size: 100% 100%;
            top: 50%;
            transform: translateY(-50%);
            height: 6px;
            width: 12px;
            right: 0;
            z-index: 999;
        }
    }
    .ant-select-selection-placeholder {
        position: relative;
        padding-left: 5px !important;
        color: #7b7b7b;
        font-size: 15px;
        font-family: "Sora";
        &::before {
            content: "";
            left: 6px;
            top: 4px;
            position: absolute;
            // background-image: url('../../assets/images/role.svg');
            background-position: center;
            background-size: cover;
            height: 21px;
            width: 21px;
            z-index: 999;
        }
    }
}
