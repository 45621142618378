.table-header {
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 22px 24px;
    border-bottom: 1px solid #DCD9D5;
    align-items: center;
    border-radius: 6px 6px 0 0;
    text-transform: capitalize;

    h3 {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 600;
    }

    .aes-btn {
        &.green-btn{
            height: 41px;
        }
    }
}