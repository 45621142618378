.input-field {
    position: relative;
    // margin-bottom: 20px;

    // @media (max-width:1366px) {
    //     margin-bottom: 10px;
    // }

    label {
        &.error-message {
            display: none;
            color: red;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 10px;
            opacity: 0;
            min-height: 16px;
            max-width: 90%;
            margin-top: 3px;
            margin-bottom: 0;
            &.displayed{
                display: block;
                opacity: 1;
            }

            @media (max-width: 1680px) {
                font-size: 12px;
                line-height: 14px;
                // margin: 6px 0;
            }

            @media (max-width: 1366px) {
                // margin-top: 3px;
                min-height: 14px;
            }

            @media (max-width: 1366px) and (max-height: 630px) {
                font-size: 10px;
                // margin-bottom: 6px;
            }

            @media (max-width: 576px) {
                font-size: 10px;
                margin: 4px 0 6px;
            }
        }
    }

    input {
        background-color: #ffffff;
        border-radius: 8px;
        // height: 47px;
        max-height: 100%;
        border: unset;
        padding: 13px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #333333;

        @media (max-width: 1600px) and (max-height: 800px) {
            // height: 46px;
        }

        @media (max-width: 1366px) {
            // font-size: 14px;
            // height: 40px;
        }

        @media (max-width: 576px) {
            // font-size: 11px;
            // height: 35px;
            // padding: 6px 10px;
        }

        &::placeholder {
            font-family: "Sora";
            color: #7b7b7b !important;
            font-weight: 300 !important;
            font-size: 15px !important;
        }

        &:focus {
            outline: none;
            box-shadow: unset !important;
            background-color: #ffffff;
        }
        &:disabled{
            background-color: #ffffff;
        }
    }

    .right-icon,
    .left-icon {
        position: absolute;
        top: 14px;
        right: 15%;
        cursor: pointer;
        margin-top: -1px;

        @media (max-width: 1600px) and (max-height: 800px) {
            height: 46px;
            top: 10px;
        }

        @media (max-width: 1366px) {
            top: 7px;

            img {
                height: 14px;
            }
        }

        @media (max-width: 576px) {
            top: 5px;

            img {
                height: 10px;
            }
        }
    }

    .left-icon {
        left: 15px;
        right: unset;
        cursor: unset;
    }

    .cvv-icon {
        height: 10px;

        @media (max-width: 1366px) {
            height: 8px !important;
        }
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        left: 13px;
        position: absolute;
        // background-image: url('../../assets/images/calendar.svg');
    }
}