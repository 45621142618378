.content-section {
    &.team-members-section{
        padding-left: 0 !important;
        min-height: unset;
        .team-members-content-section{
            padding: 0 40px 0 42px;
            .heading{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 26px;
                h1{
                    margin-bottom: 0;
                    font-size: 24px;
                    font-weight: 600;
                }
                .button-wrapper{
                    .aes-btn{
                        &.green-btn{
                            min-width: 147px;
                            height: 47px;
                        }
                    }
                }
            }
        }
    }
}