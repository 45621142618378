.content-section {
    &.adminDashboard-section {
        padding: 38px 35px 38px 41px !important;
        @media (max-width: 767px) {
            padding: 10px 15px 10px 15px !important;
        }
        .spinner {
            display: flex;
            justify-content: center;
            margin: 10px 0;
        }

        .bodyScroll {
            max-height: calc(100vh - 230px);
            top: 0px;
            @media (max-width: 767px) {
                max-height: calc(100vh - 238px);
                top: 0;
            }
            @media (max-width: 575px) {
                max-height: calc(100vh - 230px);
                top: 0;
            }
            @media (max-width: 402px) {
                max-height: calc(100vh - 280px);
                top: 0;
            }
        }
        .headingStyle {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            @media (max-width: 767px) {
                margin-bottom: 10px;
            }
            span {
                display: flex;
                gap: 10px;
                flex-grow: 1;
                align-items: baseline;
                @media (max-width: 767px) {
                    margin-bottom: 10px;
                    flex-basis: 100%;
                }
            }
            form {
                .search-field {
                    max-width: unset;
                }
                @media (max-width: 767px) {
                    flex-grow: 1;
                }
            }
            h1 {
                font-weight: 600;
                width: 100%;
                font-size: 24px;
                line-height: 50px;
                letter-spacing: -0.02em;
                color: #333333;
            }
            .buttondiv {
                text-align: right;
                @media (max-width: 576px) {
                    text-align: left;
                }
                .newFileStyle {
                    width: auto;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 30px;
                    text-align: center;
                    letter-spacing: -0.02em;
                    color: #ffffff;
                    height: 47px;
                    background: #0a5f59;
                    border: 1.4px solid #0a5f59;
                    border-radius: 4px;
                    margin: 0px;
                    min-width: 150px;
                }
            }
        }

        .contentStyle {
            background: #ffffff;
            border-radius: 4px;
            margin-bottom: 12px;
            padding: 22px 20px;

            .heading-name {
                font-weight: 500;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #333333;
            }

            .BtnsStyle {
                font-weight: 600;
                font-size: 12px;
                line-height: 30px;
                text-align: center;
                letter-spacing: -0.02em;
                color: #0a5f59;
                width: auto;
                height: 40px;
                background: none;
                border: 1.4px solid #0a5f59;
                border-radius: 4px;
                padding: 0px 24px;
                margin-left: 10px;
            }

            h2 {
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #333333;

                span {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: -0.01em;
                    color: #333333;
                }
            }
        }

        .editViewContainer {
            background: #ffffff;
            border-radius: 4px;
            padding: 22px;
            margin-right: 20px;

            h1 {
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #333333;
            }

            .BtnsStyle {
                font-weight: 600;
                font-size: 12px;
                line-height: 30px;
                text-align: center;
                letter-spacing: -0.02em;
                color: #0a5f59;
                width: auto;
                height: 40px;
                background: none;
                border: 1.4px solid #0a5f59;
                border-radius: 4px;
                padding: 0px 24px;
                margin-left: 10px;
            }

            h2 {
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #333333;
            }

            .InputStyle {
                background: #f2f0ec !important;
                border-radius: 4px;
                width: 100%;
            }

            .board-select-wrapper {
                background: #f2f0ec !important;
                border-radius: 4px;
            }

            .ant-select-selector {
                background: #f2f0ec !important;
            }

            .form-control {
                background: #f2f0ec !important;
            }
        }
    }
}


.button-filter {
    display: flex;
    background: #E0DED9;
    border: 1px solid #CBC9C6;
    border-radius: 5px;
    padding: 14px 20px 14px 16px;
    gap: 10px;
    cursor: pointer;

    p {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.01em;
        color: #333333;
        white-space: noWrap;

        span {
            color: #898989;
            padding-left: 5px;
            display:inline-block !important
        }

    }
}