.content {
    font-family: "Sora";
    background-color: #f2f1ec;

    &.Dashbord-section {
        height: calc(100vh - 80px);
        overflow: auto;
        padding: 35px 0px 0px 41px;

        @media(max-width: 576px) {
            padding: 20px 0px 0px 20px;
        }

        .headerText {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 20px;

            @media (max-width: 576px) {
                display: block !important;
            }

            h1 {
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }

        .contractornotification {

            padding-right: 20px;
            margin-top: 1.5rem;

            @media(max-width:576px) {
                margin-top: 10px;
            }

            .inner {
                max-height: calc(100vh - 305px);
                overflow: auto;

                @media(max-width:576px) {
                    max-height: calc(100vh - 325px);
                    margin-top: 10px;
                }
            }

            .listitem {
                margin-top: 1.5rem;

                &:first-child {
                    margin-top: 0;
                }

                .notification-section {
                    display: flex;
                    margin-top: 10px;

                    &:first-child {
                        margin-top: 0;
                    }

                    .right-section {
                        background-color: #ffff;
                        border-top: 2px solid #0a5f59;
                        border-radius: 4px;
                        padding: 12px;
                        min-width: 75px;

                        @media (max-width: 576px) {
                            padding: 8px !important;
                        }

                        .first-text {
                            font-size: 14px;

                            border-bottom: 1px solid grey;
                            margin-bottom: 0px;
                            text-align: center;
                            color: #333333;
                            padding-bottom: 6px;
                            font-weight: 600;
                            white-space: nowrap;
                        }

                        .sec-text {
                            font-size: 11px;
                            white-space: nowrap;

                            margin-bottom: 0px;
                            text-align: center;
                            color: grey;
                            padding-top: 6px;
                        }
                    }

                    .left-section {
                        background-color: #ffff;
                        flex-grow: 1;
                        margin-left: 10px;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-right: 17px;
                        padding-left: 21px;
                        margin-right: 10px;

                        @media (max-width: 576px) {
                            padding-right: 10px !important;
                            padding-left: 10px !important;
                        }

                        .title-text {
                            color: #333333;
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 0px;
                            flex-grow: 1;
                            text-transform: lowercase;

                            @media (max-width: 576px) {
                                font-size: 12px !important;
                            }
                        }
                        .title-text::first-letter {
                            text-transform: uppercase
                          }

                        .message-text {
                            color: #333333;
                            font-size: 12px;
                            font-weight: 300;
                            margin-bottom: 0px;                      
                        }
                    
                        .aes-btn {
                            min-width: 98px;

                            @media (max-width: 576px) {
                                // min-width: 50px !important;
                            }
                        }
                    }

                    &.off-notification {
                        .right-section {
                            border: 1px solid #b8b6b2;
                            background-color: #f2f0ec;

                            .first-text {
                                color: #626262;
                            }

                            .not-text {
                                color: #626262;
                            }
                        }

                        .left-section {
                            border: 1px solid #b8b6b2;
                            background-color: #f2f0ec;

                            .not-text {
                                color: #626262;
                            }

                            .smal-text {
                                color: #626262;
                            }
                        }
                    }
                }
            }
        }

        .Calendar {
            width: 310px !important;
            position: absolute;
            right: 39px;
            margin-top: 10px;
            border-radius: 10px;
            padding: 1px;

            .ant-picker-calendar-date-content {
                height: unset !important;
            }

            .ant-picker-body th {
                color: #0a5f59;
            }

            .ant-picker-calendar-date {
                border: unset;
            }
        }
    }
}

.invoiceDetailModal {
    .modal-header{
        border-bottom-color: #CBC9C6;
        padding: 20px;
    }
    .modal-dialog {
        max-width: fit-content;

        .modal-body {
            padding: 20px;

            p{
                color: #606060;
                font-size: 16px;
            }
        }

        @media(max-width: 576px) {
            width: 100%;
            max-width: 100%;
            margin: 0;
            padding: 15px;

            .modal-content {
                width: 100% !important;
            }
        }
    }

    .invoiceStyle {
        text-decoration: none;
        p {
            cursor: pointer;
            color: #0a5f59;
            font-weight: 500;
        }

        img {
            cursor: pointer;
            height: 20px;
        }
    }
}