@font-face {
    font-family: "Sora";
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url(./sora/Sora-Light.eot);
    src:
        url(./sora/Sora-Light.eot?#iefix) format("embedded-opentype"),
        url(./sora/Sora-Light.otf) format("otf"),
        url(./sora/Sora-Light.ttf) format("truetype"),
        url(./sora/Sora-Light.svg#Sora-Regular) format("svg"),
        url(./sora/Sora-Light.woff) format("woff"),
        url(./sora/Sora-Light.woff2) format("woff2");
}
@font-face {
    font-family: "Sora";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(./sora/Sora-Regular.eot);
    src:
        url(./sora/Sora-Regular.eot?#iefix) format("embedded-opentype"),
        url(./sora/Sora-Regular.otf) format("otf"),
        url(./sora/Sora-Regular.ttf) format("truetype"),
        url(./sora/Sora-Regular.svg#Sora-Regular) format("svg"),
        url(./sora/Sora-Regular.woff) format("woff"),
        url(./sora/Sora-Regular.woff2) format("woff2");
}
@font-face {
    font-family: "Sora";
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url(./sora/Sora-Medium.eot);
    src:
        url(./sora/Sora-Medium.eot?#iefix) format("embedded-opentype"),
        url(./sora/Sora-Medium.otf) format("otf"),
        url(./sora/Sora-Medium.ttf) format("truetype"),
        url(./sora/Sora-Medium.svg#Sora-Regular) format("svg"),
        url(./sora/Sora-Medium.woff) format("woff"),
        url(./sora/Sora-Medium.woff2) format("woff2");
}
@font-face {
    font-family: "Sora";
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url(./sora/Sora-SemiBold.eot);
    src:
        url(./sora/Sora-SemiBold.eot?#iefix) format("embedded-opentype"),
        url(./sora/Sora-SemiBold.otf) format("otf"),
        url(./sora/Sora-SemiBold.ttf) format("truetype"),
        url(./sora/Sora-SemiBold.svg#Sora-Regular) format("svg"),
        url(./sora/Sora-SemiBold.woff) format("woff"),
        url(./sora/Sora-SemiBold.woff2) format("woff2");
}
@font-face {
    font-family: "Sora";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url(./sora/Sora-Bold.eot);
    src:
        url(./sora/Sora-Bold.eot?#iefix) format("embedded-opentype"),
        url(./sora/Sora-Bold.otf) format("otf"),
        url(./sora/Sora-Bold.ttf) format("truetype"),
        url(./sora/Sora-Bold.svg#Sora-Regular) format("svg"),
        url(./sora/Sora-Bold.woff) format("woff"),
        url(./sora/Sora-Bold.woff2) format("woff2");
}
