.content-section {
    &.DocumentAgreement-section {
        padding: 38px 15px 38px 15px !important;

        @media (max-width: 767px) {
            padding: 10px 15px 10px 15px !important;
        }

        .spinner {
            display: flex;
            justify-content: center;
            margin: 10px 0;
        }

        .bodyScroll {
            max-height: calc(100vh - 230px);
            top: 0px;

            @media (max-width: 767px) {
                max-height: calc(100vh - 238px);
                top: 0;
            }

            @media (max-width: 575px) {
                max-height: calc(100vh - 230px);
                top: 0;
            }

            @media (max-width: 402px) {
                max-height: calc(100vh - 280px);
                top: 0;
            }
        }

        .headingStyle {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            padding: 0 52px 0 37px;
            @media (max-width: 767px) {
                margin-bottom: 10px;
            }

            span {
                display: flex;
                gap: 10px;
                flex-grow: 1;
                align-items: baseline;

                @media (max-width: 767px) {
                    margin-bottom: 10px;
                    flex-basis: 100%;
                }
            }

            form {
                .search-field {
                    max-width: unset;
                }

                @media (max-width: 767px) {
                    flex-grow: 1;
                }
            }

            h1 {
                font-weight: 600;
                width: 100%;
                font-size: 24px;
                line-height: 50px;
                letter-spacing: -0.02em;
                color: #333333;
            }

            .buttondiv {
                text-align: right;

                @media (max-width: 576px) {
                    text-align: left;
                }

                .newFileStyle {
                    width: auto;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 30px;
                    text-align: center;
                    letter-spacing: -0.02em;
                    color: #ffffff;
                    height: 47px;
                    background: #0a5f59;
                    border: 1.4px solid #0a5f59;
                    border-radius: 4px;
                    margin: 0px;
                    min-width: 150px;
                }
            }
        }

        .docomentcard {
            padding: 20px;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            border-radius: 4px;
            width: 49%;

            background: #fff;
            // width: 95%;

            .img-upload {
                border-right: 1px #cccccc solid;
                padding-right: 10px;
                width: 26.509px;
                height: 33.136px;
                // flex-shrink: 0;
            }

            .pdf-Link {
                padding-left: 10px;
                color: #0a5f59;
                font-family: "Sora";
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                /* 153.846% */
                letter-spacing: -0.13px;
                text-decoration-line: underline;
            }
        }
    }
    .inner {
        max-height: calc(100vh - 305px);
        overflow: auto;
        @media (max-width: 576px) {
            max-height: calc(100vh - 325px);
            margin-top: 10px;
        }
    }
    .loader {
        padding-right: 20px;
        margin-top: 1.5rem;
        @media (max-width: 576px) {
            margin-top: 10px;
        }
    }
}
