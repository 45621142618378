*,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
a {
    font-family: "Sora";
}

body {
    max-height: 100vh;
    font-family: "Sora";
    background-color: #f2f1ec !important;
}

.spinner-border {
    &.text-primary {
        color: #0a5f59;
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #e0ded9;
    border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0a5f59;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0a5f59;
}

.bodyScroll {
    overflow: auto;
    max-height: calc(100vh - 185px);
    position: relative;
    top: 60px;

    @media (max-width: 767px) {
        max-height: calc(100vh - 238px);
        top: 0;
    }

    @media (max-width: 575px) {
        max-height: calc(100vh - 300px);
        top: 0;
    }
}

.heading-request {
    position: fixed;
    min-width: calc(100% - 363px);

    @media (max-width: 992px) {
        min-width: calc(100% - 45px);
    }
}

.input-field {
    position: relative;
}

input {
    &::placeholder {
        font-family: "Sora";
    }

    &:focus {
        box-shadow: unset !important;
        border-color: unset !important;
        outline: none;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.opacity-1 {
    opacity: 1 !important;
}

.loader-container {
    width: 100%;
    min-height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-style {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.no-data {
    font-weight: 600;
    font-size: 32px;
}

.__PrivateStripeElement {
    top: 4px;
}

.checkbox-wrapper {
    .styled-checkbox {
        position: absolute;
        opacity: 0;

        & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
            display: flex;
            align-items: center;
        }

        & + label:before {
            content: "";
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 24px;
            height: 24px;
            background: #a2a2a2;
            border-radius: 5px;
        }

        &:checked + label:before {
            background: #6f8bdf;
        }

        &:disabled + label {
            color: #b8b8b8;
            cursor: auto;
        }

        &:disabled + label:before {
            box-shadow: none;
            background: #ddd;
        }

        &:checked + label:after {
            content: "";
            position: absolute;
            left: 2px;
            top: 2px;
            width: 20px;
            height: 20px;
            // background-image: url('../../assets/images/list-check-white.svg');
            background-position: center;
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }
}

.toggle-select {
    display: flex;
    margin-bottom: 50px;
    padding-left: 15px;

    @media (max-width: 1366px) {
        margin-bottom: 30px;
    }

    .title {
        font-size: 16px;
        color: #a2a2a2;
        font-weight: 500;
        border-bottom: 4px solid #efefef;
        max-width: 223px;
        width: 100%;
        text-align: center;
        font-family: "Sora";
        transition: all 0.3s;
        cursor: pointer;
        padding-bottom: 8px;

        &.active {
            color: #6f8bdf;
            border-color: #6f8bdf;
        }

        @media (max-width: 1200px) {
            max-width: 200px;
        }
    }
}

.headerText {
    h2 {
        font-weight: 600;
        font-size: 24px;
        color: #333333;
    }
}

ul.pagination {
    .page-item {
        .page-link {
            color: #0a5f59;

            &:hover {
                color: #fff;
                background: #0a5f59;
                border-color: transparent;
            }

            &:focus {
                box-shadow: unset;
            }
        }

        &.active {
            .page-link {
                color: #fff;
                background: #0a5f59;
                border-color: transparent;
            }
        }
    }
}

label {
    font-family: "Sora";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #333333;
}

.errordiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;

    p {
        font-size: 20px;
        font-weight: 600;
    }
}
.time{
    white-space: nowrap;
    
    font-weight: 300;
    font-size: 11px;
}

.back,.img-bg{cursor: pointer;}
