.content-section {
    &.invoice-details-section {
        padding-left: 0 !important;
        min-height: unset;

        .invoice-details-content-section {
            padding: 0 40px 0 42px;

            .heading-invoice-details {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;

                .title {
                    display: flex;
                    align-items: center;

                    h3 {
                        margin-bottom: 0;
                        font-size: 24px;
                        font-weight: 600;
                    }

                    .back {
                        margin-right: 22px;
                    }
                }

                .actions-wrapper {
                    display: flex;
                    align-items: center;

                    .search-wrapper {
                        .search-field {
                            max-height: 47px;
                        }
                    }
                }
            }

        }
    }
}