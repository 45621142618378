.teamemberTable {
    padding-right: 0;

    .ant-table {
        .ant-table-container {
            border-collapse: separate;
            border-spacing: 0 10px;

            .ant-table-thead {
                .ant-table-cell {
                    background-color: #e0ded9;
                    border-color: #cbc9c6;
                    border-top-width: 1px;
                    font-size: 13px;
                    font-weight: 600;
                    padding: 8px 16px;

                    &::before {
                        display: none;
                    }
                    &:first-child {
                        border-radius: 5px 0 0 5px;
                        border-left-width: 1px;
                    }
                    &:last-child {
                        // border-radius: 0 5px 5px 0;
                        // border-right-width: 1px;
                        display: none;
                    }
                    &:nth-child(4) {
                        color: #333333 !important;
                    }
                    &:nth-child(6) {
                        color: transparent;
                        border-radius: 0 5px 5px 0;
                        border-right-width: 1px;
                    }
                }
            }

            .ant-table-body {
                overflow: auto !important;
                tr {
                    background-color: #fff;
                    border: none !important;

                    td {
                        font-weight: 600;
                        font-size: 15px;
                        border-color: #fff !important;
                        &:first-child {
                            border-radius: 6px 0 0 6px;
                            border-left-width: 1px;
                        }
                        &:last-child {
                            border-radius: 0 6px 6px 0;
                            border-right-width: 1px;
                        }
                    }

                    &:last-child {
                        border-bottom-left-radius: 6px;
                        border-bottom-right-radius: 6px;
                    }
                }

                .ant-table-row {
                    &:last-child {
                        border-radius: 4px;
                        outline: unset;

                        td {
                            border-bottom-color: transparent;
                        }
                    }
                }

                .ant-table-cell {
                    border-radius: unset;
                    border-bottom: 1px solid #a2a2a2;

                    &.ant-table-cell-row-hover {
                        background-color: transparent;
                        border-radius: unset !important;
                    }
.phone_number{
    display: flex;
    align-items: center;
    justify-content: center;
}
                    .role {
                        // display: flex;
                        // justify-content: space-between;
                        // align-items: center;

                        p {
                            color: #7b7b7b;
                            font-size: 15px;
                            opacity: 0.9;
                            font-weight: 300;
                        }

                        .aes-btn {
                            height: 34px;
                            font-size: 12px;
                            min-width: 87px;
                            margin-left: auto;
                        }
                    }

                    .ant-space {
                        .ant-space-item {
                            color: #7b7b7b;
                            font-weight: 300;
                            opacity: 0.9;
                        }

                        &.full-name {
                            .ant-space-item {
                                color: #333333;
                                font-weight: 700;
                            }
                        }
                        &.email {
                            .ant-space-item {
                                color: #0a5f59;
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-table-placeholder{
        background-color: unset !important;
        td
        {
        background-color: unset !important;
        border: none !important;
        height: 500px;
        color: #333333;
    
        }
    }
    
}
