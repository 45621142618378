.content-section {
    &.fill-site-section {
        padding-left: 0 !important;
        min-height: unset;
        overflow: unset !important;

        .fill-site-content-section {
            padding: 0 16px 0 42px;

            .heading-Account {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 26px;
                padding-right: 24px;
                @media (max-width: 1024px) {
                    // margin-top: 3px;
                    display: block;
                   
                }

                h1 {
                    margin-bottom: 0;
                    font-size: 24px;
                    font-weight: 600;
                    @media (max-width: 1024px) {
                        // margin-top: 3px;
                        
                        margin-bottom: 20px;
                    }

                }

                .back {
                    margin-right: 14px;
                }
            }

            .fill-site-table-wrapper {
                padding-bottom: 20px;
                .switch-modes {
                    display: flex;
                    margin-bottom: 26px;

                    .mode {
                        background-color: #E0DED9;
                        border: 1px solid #CBC9C6;
                        border-radius: 4px;
                        font-size: 18px;
                        font-weight: 600;
                        padding: 0 28px;
                        height: 37px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #908E89;
                        cursor: pointer;
                        @media (max-width: 1024px) {
                            // margin-top: 3px;
                            font-size: 14px;
                            padding: 0 18px;
                        }
                        &:first-of-type{
                            margin-right: 17px;
                        }
                        &.active{
                            background-color: #fff;
                            border: none;
                            color: #333333;
                        }
                    }
                }
            }
        }
    }
}
.ant-table-thead th{
    white-space: nowrap;
}
.ant-table-cell{
    white-space: nowrap;
}
