.filter {
    background-color: #E0DED9;
    border: 1px solid #CBC9C6;
    display: flex;
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;
    align-items: center;

    @media (max-width: 576px) {
        margin-top: 20px;
      }

    .button-filter {
        background-color: #E0DED9;

        display: flex;
        align-items: center;
        padding: 16px;
        border-right: 1px solid #CBC9C6;
        gap: 10px;




    }

    p {
        margin-left: 5px;
        margin-bottom: 0px;
        color: #333333;
        white-space: nowrap;

        span {
            padding: 5px;
            color: #898989;
        }
    }

    .display-date {
        padding: 16px;

        p {
            margin-bottom: 0px;
            color: #333333;

            span {
                color: #898989;

            }

        }
    }
   
}

.filter-wrapper {
    height: 75px;
}
.WeekdatePicker{
    visibility: hidden;
    height: 0;
    padding: 0;
    width: 0;
    position: absolute;
    .ant-picker-panel {
    :not(:first-of-type) {
        display: none;
    }
    
    }
    
}
.datePicker {
    visibility: hidden;
    height: 0;
    padding: 0;
    width: 0;
    position: absolute;
  }
  .ant-picker-panel-container{
margin-top: -22px !important;
  }
  .type {
    padding:15px;
    // width: fit-content;
  cursor: pointer;
 color: black !important;
//  background-color: aqua;
                
  }
  .untype{
    color: gray;
  cursor: pointer;


  }
  .ant-picker-header-super-prev-btn{
    display: none;
  }
  .ant-picker-prev-icon::before{
    width: 10px !important;
    height: 10px !important;
    color: #333333;
  }
  .ant-picker-prev-icon:hover{
    color: #333333;
  }
  .ant-picker-next-icon:hover{
    color: #333333;
  }
  .ant-picker-next-icon::before{
    width: 10px !important;
    height: 10px !important;
    color: #333333
  }
  .ant-picker-header-super-next-btn{
    display: none;
  }
  .ant-picker-body{
    .ant-picker-content{
        th{
            color: #0A5F59;
            font-weight: 600;
            font-size: 12px;
        }
      }
    
    
  }
  
  .ant-picker-header{
    border: none !important;
  }

  .ant-picker-cell-inner::before{
border: 2px solid #0A5F59 !important;
  }

  :where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-9ntgx0).ant-picker-dropdown .ant-picker-cell-selected) .ant-picker-cell-inner{
    background-color: #0A5F59 ;
  }
  .ant-picker-panel-container{
    border-radius: unset;
  }

.dateP .ant-picker-footer{display: block !important;}