.phone-input-wrapper {
    width: 100%;

    @media (max-width: 1024px) {
        width: 100%;
    }

    .react-tel-input {
        width: 100%;

        .special-label {
            display: none;
        }

        .invalid-number-message {
            top: calc(100% + 4px);
            left: 0;
            font-size: 12px;
            color: red;
            display: none;
        }

        .form-control {
            background-color: #FFFFFF !important;
            border-radius: 8px;
            height: 52px;
            max-height: 100%;
            border: unset;
            // padding: 6px 52px 6px 52px;
            width: 100%;

            @media (max-width: 1600px) and (max-height: 800px) {
                height: 46px;
            }

            @media (max-width:1366px) {
                font-size: 14px;
                height: 40px;
            }

            @media(max-width: 576px) {
                font-size: 11px;
                height: 35px;
                padding: 6px 40px;
            }

            &::placeholder {
                color: #7b7b7b;
                font-weight: 300;
            }

            &:focus {
                outline: none;
                box-shadow: unset !important;
                background-color: #efefef;
            }
            &.invalid-number{
                background-color: #efefef;
                border: unset;
                &:focus{
                    background-color: #efefef;
                    border: unset;
                }
            }
        }

        .flag-dropdown {
            background-color: #efefef;
            border: unset;

            .selected-flag {
                &:hover {
                    background-color: #dedcdc;
                }
            }
        }
    }
    label {
        &.error-message {
            display: none;
            opacity: 0;
            &.displayed{
                display: block;
                opacity: 1;
            }
        }
    }
}