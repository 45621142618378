.content-section {
    &.add-team-members-section {
        // padding-left: 0 !important;

        .add-team-members-content-section {
            padding: 0 40px 0 42px;
            @media (max-width: 760px) {
                padding: 0 20px 0 20px;
            }

            .heading-add-team-members {
                display: flex;
                align-items: center;
                margin-bottom: 26px;

                h1 {
                    margin-bottom: 0;
                    font-size: 24px;
                    font-weight: 600;
                    @media (max-width: 760px) {
                        font-size: 20px !important;
                    }
                }
                .back {
                    margin-right: 14px;
                }
            }
            .add-members-form-section {
                // margin-left: -15px;
                .add-member-field {
                    margin-bottom: 16px;
                }
                .button-wrapper {
                    margin-top: 14px;
                    padding-bottom: 70px;
                    .aes-btn {
                        &.green-btn {
                            min-width: 147px;
                            height: 48px;
                        }
                    }
                }
            }
        }
    }
}
