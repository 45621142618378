.search-wrapper {
    position: relative;

    .search-field{
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 4px;
        padding: 2px 4px;
        max-width: 239px;
        .search-icon{
            background-color: #0A5F59;
            min-height: 42px;
            min-width: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
        }
        .input-field{
            background-color: transparent;
            input{
                padding: 6px 10px;
            }
        }
    }

    label {
        display: none !important;
    }

    .input-field{
        .left-icon{
            display: none;
        }
    }

}