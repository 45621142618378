.soil-deposite-request-section {
    padding-left: 42px!important;

    .heading-request {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 26px;
        padding-right: 24px;
        font-weight: 600;
        font-size: 24px;

h1{
    font-weight: 600;
font-size: 24px;
line-height: 50px;
/* identical to box height, or 208% */

letter-spacing: -0.02em;

color: #333333;

}
    }

    .soil-request-table-wrapper {
        margin-bottom: 26px;
        padding-right: 24px;
        .contractor-name{
            font-weight: 600;
            color: #333333;
        }
        .column{
            font-weight: 300;
            color: #333333;
        }

        table {
            thead{
                .ant-table-cell{
                    font-weight: 500!important;
                    font-size: 13px!important;

                }
                .ant-table-cell-scrollbar{
                    
                    display: none;
                    background-color: transparent;
                }
            }

            tbody {
                tr {
                    td {
font-weight: 300!important;
                        &:last-child {
                            // display: flex;
                            // flex-direction: row-reverse;
                            button{

                                height: 34px;
                            }

                        }
                    }

                }
            }
        }
    }
}

.button-filter {
    display: flex;
    background: #E0DED9;
    border: 1px solid #CBC9C6;
    border-radius: 5px;
    padding: 14px 20px 14px 16px;
    gap: 10px;
    cursor: pointer;

    p {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.01em;
        color: #333333;
        white-space: noWrap;

        span {
            color: #898989;
            padding-left: 5px;
        }

    }
}