.siteContainer {
    padding: 20px 40px;
    max-height: calc(100vh - 85px);
    overflow: auto;
    @media (max-width: 800px) {
        padding: 20px 15px;
    }
    .heading{
        display: flex;
        align-items: center;
        margin-bottom: 21px;
        .back{
            margin-right: 15px;
        }
        h1{
            margin-bottom: 0;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 0px;
            letter-spacing: -0.02em;
            color: #333333;
        }
    }
   

    .inputWrapper {
        margin-bottom: 10px;

        h2 {
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #333333;
        }
        input {
            font-weight: 300;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #7b7b7b;
        }
    }

    .materialSiteStyle {
        h1 {
            font-weight: 600;
            font-size: 20px;
            line-height: 50px;
            letter-spacing: -0.02em;
            color: #333333;
        }
        .checkoxes {
            display: flex;
            gap: 15px;
            @media (max-width: 800px) {
                flex-direction: column;
                .ant-checkbox-wrapper {
                    margin: 0;
                }
            }
        }
        .ant-checkbox-wrapper {
            align-items: center;
            span:last-child {
                font-weight: 300;
                font-size: 15px;
                line-height: 26px;
                text-align: center;
                letter-spacing: -0.01em;
                color: #606060;
                height: 20px;
            }
            .ant-checkbox {
                .ant-checkbox-inner {
                    border-radius: 2px;
                    background-color: transparent;
                    border: 1px solid #7b7b7b;
                    width: 20px;
                    height: 20px;
                    transition: none;
                    &::before {
                        border-radius: 1px;
                        background-color: white;
                        border: 1px solid #0a5f59;
                    }
                    &::after {
                        border-color: #0a5f59;
                        left: 5px;
                        top: 9px;
                    }
                }
            }
            &:not(.ant-checkbox-wrapper-disabled) {
                &:hover {
                    transition: none;
                    .ant-checkbox-checked {
                        &:not(.ant-checkbox-disabled) {
                            .ant-checkbox-inner {
                                border-color: #7b7b7b;
                                background-color: transparent;
                            }
                            &:after {
                                border-color: transparent;
                                transition: none;
                            }
                        }
                    }
                }
            }
        }

        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
            .ant-checkbox-checked:not(.ant-checkbox-disabled)
            .ant-checkbox-inner {
            // border: 1px solid #0a5f59;
            // background-color: #0a5f59;
        }

        .ant-checkbox .ant-checkbox-inner:after {
            // color: #0a5f59 !important;
            // background: #0a5f59;
        }
    }

    .noteAreaStyle {
        padding-top: 20px;

        h1 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #333333;
        }

        textarea {
            width: 100% !important;
            height: 110px;
            max-height: fit-content;
            background-color: #ffffff;
            border-radius: 8px;
            border: unset;
            padding: 6px 10px 6px 15px;
        }
    }

    .SaveBtnStyle {
        width: 128px;
        height: 48px;
        background: #0a5f59;
        border-radius: 4px;
        font-weight: 600;
        font-size: 15px;
        line-height: 30px;
        text-align: center;
        margin-top: 29px;
        border: none;
        letter-spacing: -0.02em;
        color: #ffffff;
    }
}
