//variables
$primary: #0A5F59;
$table-head-border: #DCD9D5;
$table-head-bg: rgba(251, 249, 247, 1);

.notification-wrapper {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    margin: 22px 0;

    .notification-item {
        border-radius: 4px;
    }

    .date-time-wrapper {
        background-color: white;
        border-top: 4px solid $primary;
        border-radius: 4px;
        text-align: center;
        padding: 8px;
        width: 82px;
        min-width: 82px;

        .date {
            font-weight: 600;
        }

        hr {
            width: 85%;
            border-top: 1px solid black;
            margin: 4px auto;
        }

    }

    .notification-body {
        background-color: white;
        width: 100%;
        .ant-btn-primary {
            background-color: white;
            color: $primary;
            border-color: $primary;
        }

        .notification-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 12px;

            .notification-title {
                h3{
                    font-size: 16px;
                    font-weight: 600;
                    padding: 14px 0;
                    margin: 0;
                }
            }

            .notification-subtitle {
                
                    font-size: 14px;
                    font-weight: 300;
                    text-transform:lowercase;
                
            }
            .notification-subtitle::first-letter {
                text-transform: uppercase
              }


        }

        .notification-body {
            .notification-table {

                .ant-table-thead {
                    border: 1px solid $table-head-border;
                    border-radius: 0px;

                    th {
                        background: $table-head-bg;

                        &:first-child,
                        &:last-child {
                            // important!!!!!
                            border-radius: 0 !important;
                        }
                    }

                }
            }
        }
    }
}