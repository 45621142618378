.details-wrapper {

    .details-item {
        margin-bottom: 18px;
        .top-header {
            background-color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 26px 24px;
            border-radius: 4px 4px 0px 0px;
            border-bottom: 1px solid #DCD9D5;
            text-transform: capitalize;

            &.closed {
                border-radius: 4px;

                .right-side {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }

            .right-side {
                cursor: pointer;
                span, .icon {
                    transition: all 0.2s;
                }
            }


            h3 {
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 0;
                color: #333333;
            }

            span {
                color: #0A5F59;
                font-weight: 600;
                font-size: 15px;
                margin-right: 10px;

            }
        }

        .list-item-wrapper {

            .list-item-header {
                background: #FBF9F7;

                .item-header-row {
                    margin: unset;
                    border-bottom: 1px solid #DCD9D5;

                    .item {
                        padding: 10px 12px;
                        color: #333333;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        min-height: 42px;
                    }
                }
            }

            .list-item-row {
                margin: unset;
                background-color: #fff;
                border-bottom: 1px solid #DCD9D5;

                &:nth-of-type(odd) {
                    background-color: #FBF9F7;
                }

                .list-item {
                    padding: 12px;

                    .item {
                        display: flex;
                        align-items: center;

                        img {
                            margin-right: 13px;
                        }

                        span {
                            font-size: 15px;
                            font-weight: 700;
                            color: #333333;
                        }
                    }

                    &.green {
                        color: #0A5F59;
                        font-weight: 300;
                        opacity: 0.9;
                        font-size: 15px;
                    }

                    &.yellow {
                        color: #F99806;
                    }

                    &.red {
                        color: #C13434;
                    }
                }
            }
        }

    }

}