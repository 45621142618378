.signup-section {
    font-family: "Sora";
    height: 100vh;
    overflow: hidden;

    .row {
        width: 100%;
        margin: 0;
    }

    .right-row {
        margin-top: 30px;
        display: flex;
        height: 100vh;
        // scrollbar-width: none;

        &::-webkit-scrollbar {
            // display: none;
        }
        max-height: calc(100vh - 170px);
        overflow: auto;
        // @media (max-width: 1440px) and (max-height: 800px) {

        // }

        h3 {
            color: #333333;
            font-size: 18px;
            font-weight: 600;
            font-family: "Sora";

            // margin-top: 10px
            @media (max-width: 1366px) {
                font-size: 16px;
            }
        }

        .input-wraper {
            h4 {
                font-size: 15px;
                font-weight: 500;
                color: #333333;
                margin-top: 10px;

                @media (max-width: 1366px) {
                }
            }

            .input-field {
                input {
                    @media (max-width: 1440px) {
                        // max-width: 94%;
                    }
                }
            }
        }
    }

    .left-content {
        @media (max-width: 992px) {
            display: none;
        }
    }
    .right-content {
        padding: 55px 0 30px 108px;

        @media (max-width: 1366px) {
            padding-left: 75px;
        }

        @media (max-width: 1100px) {
            padding-left: 50px;
        }
        @media (max-width: 992px) {
            padding: 35px;
        }

        .logo {
            display: flex;
            align-items: center;

            h3 {
                font-family: "Sora";
                font-weight: 600;
                font-size: 26px;
                margin-bottom: 0px;
                margin-left: 20px;
            }
        }

        .headingText {
            margin-top: 30px;
            display: flex;

            h3 {
                color: #333333;
                font-size: 18px;
                font-weight: 600;
                font-family: "Sora";
            }
        }

        .input-wrapper {
            h4 {
                font-size: 15px;
                font-weight: 500;
                color: #333333;
                margin-top: 10px;

                @media (max-width: 1366px) {
                    // font-size: 14px;
                }

                span {
                    font-size: 10px;
                    font-weight: 500;
                    color: #7b7b7b;
                    margin-left: 10px;

                    @media (max-width: 1366px) {
                        font-size: 9px;
                    }
                }
            }

            .input-field {
                input {
                    @media (max-width: 1440px) {
                        // max-width: 94%;
                    }
                }
            }
        }

        .check-wrapper {
            margin-top: 10px;
            display: flex;
            align-items: center;

            .mainChekWraper {
                align-items: center;
                display: flex;

                @media (max-width: 776px) {
                    margin-bottom: 20px;
                }
            }

            input {
                height: 20px;
                width: 20px;
            }

            .terms {
                font-size: 12px;
                font-weight: 400;
                margin-left: 12px;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                gap: 2px;

                @media (max-width: 1300px) {
                    font-size: 11px;
                    margin-left: 7px;
                }

                @media (max-width: 1200px) {
                    font-size: 10px;
                    margin-left: 7px;
                }
            }

            a {
                color: #0a5f59;
            }
        }

        .aes-btn {
            width: 100%;

            @media (max-width: 1440px) {
                max-width: 94%;
            }

            @media (max-width: 576px) {
                top: 100px;
            }
        }
    }

.input-field{
    label {
        // display: none;
    
        &.error-message {
            margin-bottom: 10px;
            margin-top: 5px !important;
        }
    }
}
}

.left-img {
    height: 100%;
    // background-color: #333333;
    // overflow: hidden;
    width: 100%;

    .img {
        background-image: url("../../../assets/images/leftImg.png");
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;
        background-size: 100% 100%;
        overflow: hidden;
        margin-left: 70px;

        @media (max-width: 992px) {
            display: none;
        }
    }
    .left {
        .aes-btn {
            min-width: 127px;
            position: absolute;
            top: 35px;
            right: 35px;
        }
    }
}

.board-select-wrapper {
    width: 95%;

    .ant-select {
        width: 100% !important;

        @media (max-width: 1440px) {
            max-width: 100%;
        }
    }
}

label {
    // display: none;

    &.error-message {
        display: block;
        color: red;
        font-size: 14px;
        opacity: 0;
        line-height: 16px;
        margin-bottom: 10px;
        opacity: 0;
        min-height: 16px;
        max-width: 90%;
        margin-top: 3px;
        margin-bottom: 10px;

        @media (max-width: 1680px) {
            font-size: 12px;
            line-height: 14px;
            // margin: 6px 0;
        }

        @media (max-width: 1366px) {
            // margin-top: 3px;
            min-height: 14px;
        }

        @media (max-width: 1366px) and (max-height: 630px) {
            font-size: 10px;
            // margin-bottom: 6px;
        }

        @media (max-width: 576px) {
            font-size: 10px;
            margin: 4px 0 6px;
        }
    }

    .left {
        overflow: hidden;
        height: 100vh;

        .img {
            background-image: url("../../../assets/images/leftImg.png");
            background-position: right;
            background-repeat: no-repeat;
            height: 100%;
            background-size: 100% 100%; // width: fit-content
            max-width: 494px;
            margin-left: auto;
        }

        @media (max-width: 1366px) and (max-height: 800px) {
            .img {
                background-size: 95% 100%;
                max-width: 474px;
            }
        }

        @media (max-width: 1200px) and (max-height: 800px) {
            .img {
                // background-size: cover;
            }
        }

        @media (max-width: 992px) {
            display: none !important;
        }
    }
}
