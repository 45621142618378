.heading-add-team-members {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.newFileStyle {
    width: auto;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
    height: 47px;
    background: #0a5f59;
    border: 1.4px solid #0a5f59;
    border-radius: 4px;
    margin: 0px;
    min-width: 150px;
}
.header-box {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .box {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        background-color: #ffffff;
        border-radius: 4px;
        padding: 25px 35px;

        h1 {
            color: #0A5F59;
            font-weight: 600;
            font-size: 50px;
            line-height: 50px;
            letter-spacing: -0.02em;
            margin-bottom: 35px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.01em;
            color: #333333;
            margin-bottom: 0;
        }
    }
}

.add-team-members-content-section {
    .invoice-table{
        .ant-table-cell:nth-child(4){
            color: #000000 !important;
        }
        .ant-table-cell:nth-child(6){
            color: transparent !important;
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }

    .searchBar {
        display: flex;
        justify-content: end;
        margin-top: 20px;
        gap: 15px;

        .button-filter {
            display: flex;
            background: #E0DED9;
            border: 1px solid #CBC9C6;
            border-radius: 5px;
            padding: 14px 20px 14px 16px;
            gap: 10px;
            cursor: pointer;

            p {
                margin-bottom: 0;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.01em;
                color: #333333;
                span{
                    color: #898989;
                    padding-left: 5px;
                }

            }
        }
    }
}

.ant-table-tbody{
    text-transform: capitalize;
    .status-column{
        color: red;
    }
}