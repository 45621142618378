.ScheduleContainer {
    background: #F2F1EC;

    .titleStyle {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        h1 {
            font-style: normal;
            margin-left: 15px;
            font-weight: 600;
            font-size: 24px;
            margin-bottom: 0px;
            display: flex;
            line-height: 50px;
            letter-spacing: -0.02em;
            color: #333333;
        }

    }

    .detailConatiner {
        background: #E7E4DE;
        border-radius: 10px;
        padding: 28px 38px;

        h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 50px;
            margin-bottom: 0px;
            letter-spacing: -0.02em;
            color: #333333;
        }

        .submitStyle {
            border-bottom: 1px solid #B8B6B2;
            padding-bottom: 20px;


            h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                letter-spacing: -0.01em;
                color: #333333;

                span {
                    font-weight: 300;
                }
            }
        }

        .btnContainer {
            h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                letter-spacing: -0.01em;
                color: #333333;
                margin-top: 39px;
                margin-bottom: 28px;

                span {
                    font-weight: 300;
                    margin-left: 10px;
                }
            }
        }
    }
}
.trackingStyle {
    padding: 0 !important;
    margin-bottom: 20px;
    .ant-table-cell:nth-child(5){
        color: #000000 !important;
        text-align: center;
    }
    .ant-table-cell:nth-child(4){
        color: #000000 !important;
      
    }
    // border: 1px solid #575656;
    .ant-row{
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 10px;
        &:nth-child(even){
            background-color: #E7E4DE;
        }
        &:nth-child(odd){
            background: #F2F1EC;
        }
    }
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #333333;

        span {
            font-weight: 300;
        }
    }
    h5{
        text-transform: capitalize;
        svg{
            color: green;
            fill: green;
        }
    }
    .btnStyle {
        justify-content: center;
        align-items: center;
        padding: 9px 15p    x;
        gap: 8px;
        height: 45px;
        background: #F6D218;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
        border: none;
    }
}