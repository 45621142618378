.content-section {
    font-family: "Sora";
    // min-height: 100vh;
    overflow: hidden;
    // background-color: aqua;
    height: 100vh;

    // @media (max-width: 1024px) {
    //     overflow: hidden;
    // }

    &.login {
        overflow: hidden;

        .right-content {
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 100vh;
            overflow: hidden;

            .login-box {
                background-color: #e7e4de;
                // height: 450px;
                min-width: 431px;
                border-radius: 10px;
                padding: 50px 50px 40px 40px;
                h3 {
                    font-family: "Sora";
                    font-weight: 600;
                    font-size: 26px;
                    text-align: center;
                }

                @media (max-width: 760px) {
                    //  position: absolute;
                    padding: 20px 20px 20px 20px;
                    min-width: 300px;
                }

                .logo {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 54px;
                        height: 54px;
                    }

                    h3 {
                        font-family: "Sora";
                        font-weight: 600;
                        font-size: 26px;
                        margin-bottom: 0px;
                        margin-left: 10px;
                    }
                }
            }

            .input-wrapper {
                margin-top: 30px;

                h4 {
                    font-size: 15px;
                    font-weight: 500;
                    margin-top: 5px;
                }

                .right-icon {
                    right: 21px;
                }
            }

            .Forgot {
                p {
                    font-size: 15px;
                    color: #0a5f59;
                    font-weight: 500;
                    margin-top: 14px;
                    text-align: end;
                    margin-bottom: 30px;

                    a {
                        color: #0a5f59;
                        cursor: pointer;
                    }
                }
            }

            .aes-btn {
                width: 100%;

                // @media (max-width: 576px) {
                //     min-width: 170px;
                //     height: 34px;
                // }

                // &.transparent-btn {
                //     margin-left: 30px;

                //     @media (max-width: 1366px) {
                //         margin-left: 20px;
                //     }

                //     @media(max-width: 576px) {
                //         margin-left: 0;
                //         margin-top: 12px;
                //     }
                // }
            }
        }
    }
}
.left {
    height: 100vh;

    .img {
        background-image: url("../../../assets/images/leftImg.png");
        background-position: right;
        background-repeat: no-repeat;
        height: 100%;
        background-size: 100% 100%; // width: fit-content
        max-width: 494px;
        margin-left: auto;
    }

    @media (max-width: 1366px) and (max-height: 800px) {
        .img {
            background-size: 95% 100%;
            max-width: 474px;
        }
    }

    @media (max-width: 1200px) and (max-height: 800px) {
        .img {
            background-size: cover;
        }
    }

    @media (max-width: 992px) {
        display: none !important;
    }
}
