.content {
    &.Request-section {
        padding-bottom: 20px;
        height: calc(100vh - 80px);
        @media(max-width: 576px){
            padding: 20px 0 0 20px;
        }
        .headerText{
            display: flex;
            gap: 10px;
            align-items: center;
            .back{
                img{
                    cursor: pointer;
                }
            }
            h1{
                color: #333;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 50px; /* 208.333% */
letter-spacing: -0.48px;
margin-bottom: 0px;
            }
            h1{
                @media(max-width: 576px){
                    font-size: 18px;
                } 
            }
        }
        .main-div {
            max-height: calc(100vh - 205px);
            overflow: auto;
            padding-right: 20px;
            margin-top: 20px;
            .requestCard {
                padding: 6px 0px 20px 0px;
                margin-top: 20px;
                border: unset;
                &:first-child{
                    margin-top: 0;
                }
                h3 {
                    font-size: 20px;
                    font-weight: 600;
                    color: #333333;
                    padding-left: 20px;
                    border-bottom: 1px solid #d1cfcb;
                    padding-bottom: 15px;

                    padding-top: 15px;
                }

                .right-text {
                    padding-top: 16px;
                    padding-bottom: 16px;
                    border-bottom: 2px solid whitesmoke;

                    p {
                        margin-bottom: 0px;
                        padding-left: 20px;
                        font-size: 15px;
                        font-weight: 600;
                        color: #333333;

                        span {
                            margin-bottom: 0px;
                            padding-left: 5px;
                            font-size: 13px;
                            font-weight: 500;
                            color: gray;
                        }
                    }
                }
            }

            .wrap-text {
                display: flex;
                margin-top: 20px;

                .wrap-text2 {
                    background-color: #e0ded9;
                    min-width: 109px;
                    min-height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    border-right: 1px solid whitesmoke;
                    border-radius: 4px;

                    p {
                        margin-bottom: 0px;
                    }
                }

                .wrap-text1 {
                    min-width: 109px;
                    min-height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #fff;
                    border-right: 1px solid whitesmoke;
                    border-radius: 4px;

                    p {
                        margin-bottom: 0px;
                    }
                }
            }

            .button-wraper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 2px solid whitesmoke;
                padding-top: 16px;
                padding-bottom: 16px;

                .aes-btn {
                    background-color: #0a5f59;
                    min-width: 147px;
                    color: #e0ded9;
                    margin-right: 20px;
                    height: 41px;
                }
            }
        }
    }
}
