.aes-btn {
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    align-items: center;
    color: #000000;
    background-color: #f6d218;
    border-radius: 4px;
    width: auto;
    max-width: 100%;
    padding: 0px 20px;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    outline: none;
    box-shadow: unset !important;
    border-style: unset;
    font-family: "Sora";
    height: 48px;
    white-space: nowrap;

    &.full-btn {
        width: 100%;
    }

    &.transparent-btn {
        background: transparent;
        color: #0a5f59;
        border: 1px solid #0a5f59;
        line-height: 1;

        &:hover {
            color: #0a5f59;
        }

        &:first-child {
            &:active {
                background-color: transparent;
            }
        }
    }
    &.danger-transparent-btn {
        background: transparent;
        color: #ca4040;
        border: 1px solid #ca4040;
        line-height: 1;
        padding: 9px 38px;
    }
    &.danger-btn {
        background-color: #ca4040;
        color: white;
    }

    &.green-btn {
        background-color: #0a5f59;
        color: #fff;
    }

    &.orange-btn {
        padding: 9px 38px;
        background-color: #f6b21a;
        color: black;
    }

    &.black-btn {
        background-color: #2b292d;
        color: #000000;
    }

    &.radius-btn {
        border-radius: 4px;
        font-size: 15px;
        padding: 9px 35px;
        font-weight: 600;
        color: #000000;
    }

    &.inactive-btn {
        background-color: #e0ded9;
        color: #000000;
        cursor: not-allowed;
    }

    span {
        img {
            margin-left: 16px;
        }
    }

    @media (max-width: 1366px) {
        line-height: 18px;
        font-size: 15px;
        min-height: 40px;
    }

    @media (max-width: 1024px) {
        min-height: unset;
        font-size: 14px;
        padding: 8px 16px;
        border-radius: 4px;
    }

    @media (max-width: 768px) {
        border-radius: 4px;
        padding: 6px 0;
        font-weight: 500;
        min-height: 34px;
    }
}
