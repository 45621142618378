.truckArivalModal {
    .modal-content {
        .modal-header {
            border-bottom: none;
            padding-bottom: 0px;
        }
        .aes-btn.green-btn {
            width: 100%;
        }
        .modal-body {
            min-height: max-content !important;
            padding-top: 0px;
            max-height: calc(100vh - 140px);
            overflow: auto;

            .ant-tabs {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                padding: 0px;
                letter-spacing: -0.01em;
                color: #a7a5a5;
                .ant-tabs-nav-list {
                    margin: auto;
                    .ant-tabs-tab {
                        &.ant-tabs-tab-active {
                            .ant-tabs-tab-btn {
                                color: #0a5f59;
                            }
                        }
                        .ant-tabs-tab-btn {
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 22px;
                            letter-spacing: -0.01em;
                            color: #a7a5a5;
                        }
                        &:hover {
                            .ant-tabs-tab-btn {
                                color: #0a5f59;
                            }
                        }
                    }
                    .ant-tabs-ink-bar {
                        background: none;
                    }
                }
                .dateCalender {
                    background: #ffffff;
                    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    width: 418px;
                    height: 356px;
                    margin: 0 auto;
                    .react-calendar {
                        width: 100%;
                        height: 100%;
                        border: none;
                        padding: 20px 30px;
                        .react-calendar__navigation {
                            button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
                            button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
                                display: none;
                            }
                            button.react-calendar__navigation__arrow {
                                font-size: 40px;
                                color: #333;
                                &:hover,
                                &:active,
                                &:focus {
                                    background: transparent;
                                }
                            }
                            button.react-calendar__navigation__label {
                                span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 18px;
                                    line-height: 22px;
                                    text-align: center;
                                    color: #333333;
                                }
                                &:hover {
                                    background: transparent;
                                    cursor: initial;
                                }
                            }
                        }
                        .react-calendar__viewContainer {
                            .react-calendar__year-view__months {
                                .react-calendar__tile {
                                    &.react-calendar__year-view__months__month {
                                        padding: 20px;
                                        font-weight: 500;
                                        font-size: 15px;
                                        line-height: 24px;
                                        letter-spacing: -0.01em;
                                        color: #333333;

                                        &:hover {
                                            background: #0a5f59;
                                            border-radius: 5px;
                                            color: #fff;
                                        }
                                    }
                                    &.react-calendar__tile--hasActive {
                                        background: #0a5f59;
                                        border-radius: 5px;
                                        color: #fff;
                                    }
                                }
                            }
                            .react-calendar__decade-view {
                                .react-calendar__tile {
                                    &.react-calendar__decade-view__years__year {
                                        padding: 20px;
                                        font-weight: 500;
                                        font-size: 15px;
                                        line-height: 24px;
                                        letter-spacing: -0.01em;
                                        color: #333333;

                                        &:hover {
                                            background: #0a5f59;
                                            border-radius: 5px;
                                            color: #fff;
                                        }
                                    }
                                    &.react-calendar__tile--hasActive {
                                        background: #0a5f59;
                                        border-radius: 5px;
                                        color: #fff;
                                    }
                                }
                            }
                            .react-calendar__month-view__weekdays {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 15px;
                                text-transform: uppercase;
                                color: #0a5f59;
                                abbr[title] {
                                    text-decoration: none;
                                }
                            }
                            .react-calendar__month-view__days {
                                .react-calendar__tile {
                                    padding: 9px 3.6667px;
                                    margin: 5px 0px;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 17px;
                                    color: #000000;
                                    &.react-calendar__month-view__days__day--neighboringMonth {
                                        color: #909090;
                                    }
                                    &:hover {
                                        background: #0a5f59;
                                        border-radius: 5px;
                                        color: #fff;
                                    }
                                    &.react-calendar__tile--active {
                                        background: #0a5f59;
                                        border-radius: 5px;
                                        color: #fff;
                                    }
                                }
                                .react-calendar__tile--now {
                                    background: transparent;
                                }
                            }
                        }
                    }
                }
            }
           

            .selectTimeStyle {
                .ant-col {
                    margin-bottom: 13px;
                    h1 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 20px;
                        margin-bottom: 5px;
                        letter-spacing: -0.01em;
                        color: #333333;
                    }
                    .InputStyle {
                        padding: 14px 16px;
                        border: 1px solid #f2f0ec;
                        width: 100%;
                        height: 48px;
                        background: #f2f0ec;
                        border-radius: 4px;
                        font-weight: 300;
                        font-size: 15px;
                        line-height: 20px;
                        letter-spacing: -0.01em;
                        color: #7b7b7b;
                        .ant-picker-input {
                            input {
                                &[title] {
                                    color: #7b7b7b;
                                }
                            }
                        }
                    }
                }
            }
        }

        .modal-footer {
            border: none;
            padding-top: 0;
            .btnStyle {
                padding: 9px 37px;
                width: 100%;
                height: 48px;
                background: #0a5f59;
                border-radius: 4px;
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 30px;
                border: none;
                text-align: center;
                letter-spacing: -0.02em;
                color: #ffffff;

                &:active {
                    background: #0a5f59;
                }
            }
        }
    }
}

.workingTime {
    z-index: 1055;
    max-width: 175px;
    .ant-picker-footer {
        z-index: 1055px;
        display: flex;
        width: 100%;
        ul {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }
    .ant-picker-ok {
        margin-left: 169px;
    }
}
.ScheduleDaysStyle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .daylist {
        &:first-child {
            // order: 7;
        }
    }
    .days {
        width: 50px;
        height: 50px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        cursor: pointer;
        line-height: 15px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #0a5f59;
        background: #f2f0ec;
    }
    .selectedDays {
        width: 50px;
        height: 50px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        cursor: pointer;
        line-height: 15px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0a5f59;
        color: #f2f0ec;
    }
}