.content-section {

    //    background-color: red;
    &.Material-section {
        // overflow: hidden;
        padding: 0 40px 0 42px;
        // .main-section{
        overflow: hidden;

h1{
    color: #333;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 50px; /* 208.333% */
letter-spacing: -0.48px;
}

        .label {
            margin-bottom: 5px;
            font-size: 15px;
            font-weight: 500;
            white-space: nowrap;
        }

        span {
            white-space: nowrap;
            color: #606060;
            font-size: 15px;
            font-weight: 300;
        }

        .body-screen {
            padding-bottom: 25px;
            position: relative;

            .expand {
                padding-right: 50px;
            }

            .close-icon {
                position: absolute;
                right: 2px;
                top: 46px;
            }

            &:first-child {
                position: unset;

                .expand {
                    padding-right: 0;
                }

                .close-icon {
                    display: none;
                }
            }

            .board-select-wrapper {
                margin-top: 8px;
            }

            .material-field {
                @media (max-width: 1050px) {
                    //  position: absolute;
                    width: 100%
                }

                &.non-structural {
                    margin-top: 23px;

                    @media (max-width: 760px) {
                        //  position: absolute;
                        margin-top: 0;
                    }
                }

                &.inputfeild {
                    margin-top: 40px;

                    @media (max-width: 760px) {
                        //  position: absolute;
                        margin-top: 0;
                    }
                }

            }

            .checkbox-wrapper {
                display: flex;
                align-items: center;
                margin-top: 20px;

                .checkbox-round {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    border-radius: 50%;
                    vertical-align: middle;
                    border: 1px solid #7B7B7B !important;
                    appearance: none;
                    -webkit-appearance: none;
                    outline: none;
                    cursor: pointer;
                    position: relative;
                    padding: 0;
                }

                .checkbox-round:checked {
                    background-image: url('../../../assets/images/checks.svg');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 20px;
                    width: 20px;
                }

                span {
                    margin-left: 8px;
                }
            }

            .checkbox-wrapper2 {
                display: flex;
                align-items: center;
                margin-top: 10px;


                .checkbox-round {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    border-radius: 50%;
                    vertical-align: middle;
                    border: 1px solid #7B7B7B !important;
                    appearance: none;
                    -webkit-appearance: none;
                    outline: none;
                    cursor: pointer;
                    position: relative;
                    padding: 0;
                }

                .checkbox-round:checked {
                    background-image: url('../../../assets/images/checks.svg');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 20px;
                    width: 20px;
                }

                span {
                    margin-left: 8px;
                }
            }

            // .checkbox-wrapper2 {
            //     display: flex;
            //     align-items: center;
            //     margin-top: -px;

            // }

        }

        .button-wrapper {
            margin-top: 20px;

            .aes-btn {
                min-width: 221px;
                height: 47px;

                &.transparent-btn {
                    min-width: 144px;
                }
            }
        }

        .fotter {
            margin-top: 20px;

            h2 {
                font-size: 26px;
                font-weight: 600;

            }

        }

        .upload-file-soil {
            display: flex;
            align-items: center;
            gap: 30px;
            margin-top: 40px;
            // display: inline-block;

            p {
                margin-bottom: 0px;
            }

            a {
                color: #0A5F59;
                font-size: 15px;
                font-weight: 600;
            }
        }


    }

    .doted {
        border-bottom: 1px dashed gray;

        padding-bottom: 30px;

    }
}

.error-message {
    font-size: 14px;
    color: red;
    max-width: 100% !important;
    margin-top: 4px !important;
}

.check {
    display: flex;
    align-items: center;
    margin-top: 3px;
}

.error-name {
    margin-right: -76px;
}

.text-center {
    text-align: end;
    white-space: nowrap;
    margin-left: 55px;
}

.action {
    min-height: 50px;
    padding: 8px;
    // width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: #c10d1f;
    margin-left: 10px;
    cursor: pointer;

    p {
        margin: 0%;
        font-size: 15px;
        font-weight: 600;
        color: azure;
    }
}

.file-item {
    display: flex;
    align-items: center;
    margin-right: 10px;

    .file-name {
        padding: 10px;
        background-color: #0A5F59;
        border-radius: 12px;
        color: white;
        margin: 0;

    }
}
.selector-api .ant-select-selector{height: 45px !important;}
.selector-api .ant-space{
    height: 45px !important;
}
.selector-api .ant-select-selection-placeholder{
    line-height: 45px !important;
}
// }