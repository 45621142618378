.modal-body {
    font-family: "Sora";
    min-width: 556px;
    // min-height: 400px;

    @media (max-width: 576px) {
        // width: 100px;
        min-width: 300px;
    }

    .close-icon {
        text-align: end;
        cursor: pointer;
    }

    .time-text {
        p {
            color: #333333;
            font-size: 15px;
            font-weight: 600;

            span {
                color: gray;
                font-size: 15px;
                font-weight: 300;
            }
        }
    }
    .text-body {
        .heading-text {
            color: #262626;
            font-size: 24px;
            font-weight: 600;
            // margin-bottom: 20px;
        }

        .lable {
            font-size: 20px;
            font-size: 15px;
            font-weight: 600;
        }
        .para {
            color: #626262;
            font-size: 15px;
            font-weight: 300;
            margin-bottom: 10px;
            margin-top: 20px;
        }

        .img-wrap {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 50px;
            flex-wrap: wrap;
            margin-bottom: 10px;

            @media (max-width: 576px) {
                display: block !important;
            }

            img {
                width: 218px;
                height: 137px;

                @media (max-width: 576px) {
                    // width: 100px;
                    margin-top: 10px;
                }
            }
        }

        .button-wraper {
            margin-top: 40px;
            margin-bottom: 10px;

            .aes-btn {
                width: 100%;
            }
        }
    }
   
}
.modal-dialog-centered {
    justify-content: center !important;
}
.modal-content {
    width: fit-content !important;
}
