//variables
$confirm-body-bg: #E7E4DE;
$notes-bg: #F2F0EC;

.soil-request-confirm-section {
    padding: 45px 16px 0 42px;

    .heading-confirm {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;

        h1 {
            margin-bottom: 0;
            font-size: 24px;
            font-weight: 600;
        }


    }

    .body-confirm {

        background-color: $confirm-body-bg;
        padding: 26px 32px 40px 32px;
        border-radius: 10px;

        .report-detail-form {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;

            input {
                min-width: 325px;
                padding: 14px 15px;
            }

            .fill-site-select {
                p {
                    margin-bottom: 0;
                }

                min-width: 325px;

                .ant-select {

                    width: 100%;

                    .ant-select-selector {
                        // padding: 13px 10px 6px 14px;
                        height: 40px;
                        border: transparent;
                        .ant-select-selection-placeholder{
                            color: #7B7B7B;
                        }
                        .ant-select-arrow{
                            color: #333333;
                        }
                    }
                }
            }

        }

        .doc-wrapper {
            .document {
                display: flex;
                gap: 7%;

                .logo-wrapper {
                    border: 1px dashed #C7C5C1;
                    padding: 20px;
                    background-color: #F2F0EC;
                    border-radius: 6px;
                    

                    .logo-text {
                        text-align: center;
                        max-width: 160px;
                        &:hover{
                            cursor: initial;
                        }
                    }
                }
            }

            .confirm-btn {
                margin-top: 30px;
            }
        }

        .company-info {
            display: flex;
            gap: 29px;

            .address {
                max-width: 312px;

                p {
                    margin-bottom: 0;
                }
            }

            .material-info {}

            .material-analytical-data {

                .logo-wrapper {
                    img {

                        background-color: white;
                        padding: 9px 12px;
                        border-radius: 4px;
                    }
                }
            }
        }
    }

    .notes-wrapper {
        background-color: $notes-bg;
        border-radius: 4px;
        padding: 9.42px 32px 20.98px 26.03px;
        margin-top: 35px;
    }

    .button-wrapper {
        margin-top: 35px;
        display: flex;
        justify-items: flex-start;
        gap: 14px;
    }

    .error-flex{
        display: flex;
        align-items: center;
        gap: 4%;
    }
}
.poie{margin-bottom: 15px;}
.poie .input-field,.poie .fill-site-select{width: 48%;}
.poie p{margin-bottom: 0px;}
.poie  .ant-select-selector,.poie  .ant-select-selection-search-input{height: 45px !important;}
.poie  .ant-select-selection-item,.poie  .ant-select-selection-placeholder{line-height: 40px !important;}