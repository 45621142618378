.ant-table-wrapper {
    padding-right: 0;

    .ant-table {
        font-family: "Sora";
        background-color: transparent !important;

        .ant-table-container {
            table {
                border-collapse: separate;
                border-spacing: 0 10px;

                .ant-table-thead {
                    th {
                        background-color: #e0ded9;
                        border-color: #cbc9c6;
                        border-top-width: 1px;
                        font-size: 13px;
                        font-weight: 600;
                        padding: 8px 16px;

                        &::before {
                            display: none;
                        }

                        &:first-child {
                            border-radius: 5px 0 0 5px;
                            border-left-width: 1px;
                        }

                        &:last-child {
                            border-radius: 0 5px 5px 0;
                            border-right-width: 1px;
                        }
                    }

                    .ant-table-cell:nth-child(4) {
                        // color: transparent;
                    }
                }

                .ant-table-tbody {
                    .role {
                        p {
                            color: #7b7b7b;
                            font-size: 15px;
                            opacity: 0.9;
                            font-weight: 700;
                        }

                        .green-color {
                            color: #0a5f59;
                            font-size: 16px;
                            opacity: 0.9;
                            font-weight: 600;
                        }

                        .red-color {
                            color: red;
                            font-size: 16px;
                            opacity: 0.9;
                            font-weight: 600;
                        }
                    }

                    .roleAction {
                        display: flex;
                        justify-content: end;
                        align-items: center;

                        .aes-btn {
                            height: 34px;
                            font-size: 12px;
                            min-width: 87px;
                            margin-left: 10px;
                        }
                    }

                    tr {
                        background-color: #fff;
                        border: none !important;

                        td {
                            font-weight: 600;
                            font-size: 15px;
                            border-color: #fff !important;

                            &:first-child {
                                border-radius: 6px 0 0 6px;
                                border-left-width: 1px;
                            }

                            &:last-child {
                                border-radius: 0 6px 6px 0;
                                border-right-width: 1px;
                            }
                        }

                        &:last-child {
                            border-bottom-left-radius: 6px;
                            border-bottom-right-radius: 6px;
                        }
                    }

                    .ant-table-row {
                        &:last-child {
                            border-radius: 4px;
                            outline: unset;

                            td {
                                border-bottom-color: transparent;
                            }
                        }
                    }

                    .ant-table-cell {
                        border-radius: unset;
                        border-bottom: 1px solid #a2a2a2;

                        &.ant-table-cell-row-hover {
                            background-color: transparent;
                            border-radius: unset !important;
                        }

                        .ant-space {
                            .ant-space-item {
                                color: #7b7b7b;
                                font-weight: 300;
                                opacity: 0.9;
                            }

                            &.full-name {
                                .ant-space-item {
                                    color: #333333;
                                    font-weight: 700;
                                }
                            }

                            &.email {
                                .ant-space-item {
                                    color: #0a5f59;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.fill-site-table2 {
    // overflow: auto;
    max-height: unset;

    // padding-right: 19px;
    &::-webkit-scrollbar {
        width: 5px;
        background-color: #e0ded9;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: #0a5f59;
        border-radius: 4px;
    }

    .ant-table-content {
        table {
            // border-spacing: unset !important;
        }
    }
}