.RequestSoilModal-modal {
    .modal-dialog-centered {
        justify-content: center !important;
    }

    .modal-content {
        @media (min-width: 1680px) {
            min-width: 700px;
        }
    }
    .modal-body {
        font-family: "Sora";
        min-width: 500px;
        padding: 0;
        position: relative;
        min-height: 0 !important;
        .close-icon {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .main-div {
            padding-top: 51px;

            .heading-Congrats {
                text-align: center;
                border-bottom: 1px solid rgb(148, 148, 148);
                padding-bottom: 30px;

                margin-bottom: 20px;

                h3 {
                    font-size: 24px;
                    font-weight: 700;
                    color: #333333;
                }
            }

            .modal-pra {
                padding: 20px 33px 44px 40px;

                h4 {
                    font-size: 22px;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 15px;
                }

                p {
                    font-size: 15px;
                    color: #626262;
                    font-weight: 300;
                }

                span {
                    font-size: 15px;
                    color: #333333;
                    font-weight: 500;
                }
            }
        }
    }

    .button-wrapper {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        .aes-btn {
            font-size: 13px;
            min-width: 143px;
            min-height: 48px;
        }
    }
}
