//variables
$reject-body-bg: #E7E4DE;
$notes-bg: #F2F0EC;

.soil-request-reject-section {
    padding: 45px 16px 0 42px;
    .heading-reject {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        h3 {
            margin-bottom: 0;
            font-size: 24px;
            font-weight: 600;
        }


    }
    .input-field input{
        height: 200px;
    }

    .body-reject {

        background-color: $reject-body-bg;
        margin-top: 26px;
        padding: 26px 32px 40px 32px;
        border-radius: 10px;
        height: 659px;
        .note-text{
            font-size: 15px;
            color: #333333;
            font-weight: 500;
        }
        .note-textarea{

            width: 100%;
              height: 213px;
              padding: 12px 20px;
              box-sizing: border-box;
              border: 2px solid transparent;
              border-radius: 4px;
              background-color: #FFFFFF;
              font-size: 16px;
              resize: none;
        &::placeholder{
            color: #7B7B7B;
            font-size: 15px;
            font-weight: 300;
        }
            }
            .reject-btn{
                margin-top: 26px;
            }
           

       
    }

}
.note-text{
    font-size: 15px;
    color: #333333;
    font-weight: 500;
}
.note-textarea{

    width: 100%;
      height: 213px;
      padding: 12px 20px;
      box-sizing: border-box;
      border: 2px solid transparent;
      border-radius: 4px;
      background-color: #FFFFFF;
      font-size: 16px;
      resize: none;
&::placeholder{
    color: #7B7B7B;
    font-size: 15px;
    font-weight: 300;
}
    }