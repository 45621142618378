.content-section {
    &.single-summary-section {
        padding-left: 0 !important;
        min-height: unset;

        .single-summary-content-section {
            padding: 0 40px 0 42px;

            .heading-Account {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;

                .title {
                    display: flex;
                    align-items: center;

                    h1 {
                        margin-bottom: 0;
                        font-size: 24px;
                        font-weight: 600;
                    }

                    .back {
                        margin-right: 22px;
                        cursor: pointer;
                    }
                }

                .actions-wrapper {
                    display: flex;
                    align-items: center;

                    .search-wrapper {
                        .search-field {
                            max-height: 47px;
                        }
                    }

                    .filter-wrapper {
                        height: 47px;
                        margin-left: 15px;

                        .filter {
                            max-height: 47px;

                            .date {
                                padding: 12px 16px 16px;
                            }
                        }
                    }
                }
            }

            .single-summary-table-wrapper {
                overflow: auto !important;

                &::-webkit-scrollbar {
                    height: 5px;
                    background-color: #E0DED9;
                    border-radius: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    height: 5px;
                    background-color: #0A5F59;
                    border-radius: 4px;
                }
            }

        }
        .ant-picker-range{
            width: 0px;
            height: 0px;
        }
        .anticon svg {
            display: none;
        }
    }
}