.content-section {
    &.team-member-profile-section {
        padding-left: 0 !important;

        .team-member-profile-content-section {
            padding: 0 40px 0 42px;

            .heading {
                display: flex;
                align-items: center;
                margin-bottom: 26px;

                h3 {
                    margin-bottom: 0;
                    font-size: 24px;
                    font-weight: 600;
                }
                .back{
                    margin-right: 14px;
                }
            }
            .add-members-form-section{
                // margin-left: -15px;
                .add-member-field{
                    margin-bottom: 16px;
                }
                .button-wrapper{
                    margin-top: 14px;
                    display: flex;
                    align-items: center;
                    .aes-btn{
                        &.green-btn{
                            min-width: 147px;
                            height: 48px;
                        }
                    }
                    .change{
                        color: #0A5F59;
                        font-size: 15px;
                        font-weight: 600;
                        padding-bottom: 1px;
                        border-bottom: 1px solid #0A5F59;
                        margin-left: 42px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}