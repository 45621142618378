.content-section {
    &.account-info-section {
        // padding-left: 0 !important;
        overflow: hidden;
        .ant-select {
            max-width: 100% !important;
        }

        .account-info-content-section {
            padding: 0 40px 50px 42px;
            @media (max-width: 576px) {
                padding: 0 12px 55px 12px;
            }

            .heading-account-info {
                margin-bottom: 30px;

                h3 {
                    margin-bottom: 0;
                    font-size: 24px;
                    font-weight: 600;
                    @media (max-width: 576px) {
                        font-size: 15px;
                    }
                }
            }

            .top-form-section,
            .bottom-form-section {
                .field {
                    margin-bottom: 16px;
                    // padding-left: 0;
                    // padding-right: 22px;
                    .input-field {
                        label {
                            font-size: 15px;
                        }
                    }
                }

               
            }
          
            .top-form-section {
                border-bottom: 1px solid #b8b6b2;

                .top-form-row {
                    margin-bottom: 42px;
                }

                .field {
                    margin-bottom: 0;
                }
            }
        }
        .fieldl {
            @media (max-width: 760px) {
                margin-top: 40px;
                padding-right: 22px;
                padding-left: 0;
            }

            .bottom-form-section {
                .bottom-form-row {
                    margin-top: 22px;
                }
            }
        }
    }
}
.field-heading {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
}
.button-wrapper {
    margin-top: 14px;
    display: flex;
    align-items: center;
    // padding-left: 0;
    padding-bottom: 20px;

    .aes-btn {
        &.green-btn {
            min-width: 147px;
            height: 48px;
            @media (max-width: 576px) {
                min-width: 110px;
            }
        }
    }

    .change {
        color: #0a5f59;
        font-size: 15px;
        font-weight: 600;
        padding-bottom: 1px;
        border-bottom: 1px solid #0a5f59;
        margin-left: 42px;
        cursor: pointer;
        @media (max-width: 576px) {
            font-size: 13px;
            margin-left: 12px;
            white-space: nowrap;
        }
    }
}