.breadcrumbStyle {
    border: 1.6px solid #0B605B;
    border-radius: 50%;
    height: 28px;
    text-align: center;
    cursor: pointer;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;

    .anticon {
        color: #0B605B;

        .anticon svg {
            height: 16px;
            width: 16px;
        }
    }
}