.content-section {
    &.Reports {
        padding: 38px 15px 38px 15px !important;

        @media (max-width: 767px) {
            padding: 10px 15px 10px 15px !important;
        }

        .spinner {
            display: flex;
            justify-content: center;
            margin: 10px 0;
        }

        .bodyScroll {
            max-height: calc(100vh - 230px);
            top: 0px;

            @media (max-width: 767px) {
                max-height: calc(100vh - 238px);
                top: 0;
            }

            @media (max-width: 575px) {
                max-height: calc(100vh - 230px);
                top: 0;
            }

            @media (max-width: 402px) {
                max-height: calc(100vh - 280px);
                top: 0;
            }
        }

        .headingStyle {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            padding: 0 52px 0 37px;

            @media (max-width: 767px) {
                margin-bottom: 10px;
            }

            span {
                display: flex;
                gap: 10px;
                flex-grow: 1;
                align-items: baseline;

                @media (max-width: 767px) {
                    margin-bottom: 10px;
                    flex-basis: 100%;
                }
            }

            form {
                .search-field {
                    max-width: unset;
                }

                @media (max-width: 767px) {
                    flex-grow: 1;
                }
            }

            h1 {
                font-weight: 600;
                width: 100%;
                font-size: 24px;
                line-height: 50px;
                letter-spacing: -0.02em;
                color: #333333;
            }

            .buttondiv {
                text-align: right;

                @media (max-width: 576px) {
                    text-align: left;
                }

                .newFileStyle {
                    width: auto;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 30px;
                    text-align: center;
                    letter-spacing: -0.02em;
                    color: #ffffff;
                    height: 47px;
                    background: #0a5f59;
                    border: 1.4px solid #0a5f59;
                    border-radius: 4px;
                    margin: 0px;
                    padding-right: 10px;
                    padding-left: 10px;
                }
            }
        }
    }

    .report-card {
        background-color: #ffffff;
        padding: 20px;
        height: 69.504px;
        display: flex;
        align-items: center;
        margin: 13px 48px 10px 38px;
    }

    .date {
        width: 78px;
        height: 30px;
        border-radius: 4px;
        // background: #e4f4f3;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: #0a5f59;
            font-family: "Sora";
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 153.846% */
            letter-spacing: -0.26px;
        }
    }

    .right-side {
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ccc;
        height: 69.504px;

        &.noline {
            border: unset;
        }
    }

    .text {
        color: #333;
        font-family: "Sora";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 120% */
        letter-spacing: -0.2px;
        margin-left: 16px;
        margin-bottom: 0;
    }

    .heading-confirm {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;

        h1 {
            margin-bottom: 0;
            font-size: 24px;
            font-weight: 600;
        }
    }

    .background {
        background-color: #ffffff;
        margin-top: 33px;
        padding: 0 20px;
    }

    .bottomline {
        border-bottom: 1px solid #dcd9d5;

        display: flex;
        align-items: center;
    }

    .right-text {
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 2px solid whitesmoke;

        p {
            margin-bottom: 0px;
            font-size: 15px;
            font-weight: 600;
            color: #333333;

            span {
                margin-bottom: 0px;
                padding-left: 5px;
                font-size: 13px;
                font-weight: 500;
                color: gray;
            }
        }
    }

    .truck-div {
        .img-div {
            max-height: 200px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
            border-radius: 5px;
            padding: 5px;
            img {
                object-fit: contain;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }

    .truck {
        width: 243.693px;
        height: 153.146px;
        flex-shrink: 0;
        margin-top: 20px;
    }
}
.image-popup-modal {
    .modal-dialog {
        max-width: 800px;
        justify-content: center;
        .modal-content {
            max-height: calc(100vh - 70px);
            .modal-body {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-height: 700px;
                }
            }
        }
    }
}
