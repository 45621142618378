.content-section {
    &.soil-form-section {
        .soil-form-content-section {
            padding: 0 40px 0 42px;

            .heading-soil-form {
                display: flex;
                align-items: center;
                margin-bottom: 21px;

                .back {
                    margin-right: 15px;
                }

                h1 {
                    margin-bottom: 0;
                    font-size: 24px;
                    font-weight: 600;
                }
            }

            .soil-authorization-form {
                background-color: #E7E4DE;
                color: #333333;
                border-radius: 10px;
                padding: 20px 35px 84px;

                .top-row {
                    border-bottom: 1px solid #B8B6B2;
                    padding-bottom: 50px;

                    .form-heading {
                        font-size: 18px;
                        margin-bottom: 16px;
                        font-weight: 600;
                        padding-left: 0;
                    }

                    .details-wrapper {
                        padding-left: 0;
                        padding-right: 20px;

                        .details {
                            font-size: 15px;
                            font-weight: 600;
                            margin-bottom: 6px;
                            line-height: 24px;
                            letter-spacing: -0.5px;

                            span {
                                font-weight: 300;
                                opacity: 0.8;
                                margin-left: 2px;
                            }
                        }

                        &:last-of-type {
                            padding-left: 20px;
                            padding-right: 0;
                        }
                    }
                }

                .bottom-row {
                    border-bottom: 1px solid #B8B6B2;
                    padding-bottom: 41px;

                    .form-heading {
                        font-size: 18px;
                        margin: 30px 0 25px;
                        font-weight: 600;
                        padding-left: 0;
                    }

                    .input-details-wrapper {
                        padding-left: 0;

                        label {
                            font-size: 15px;
                        }

                        .input-label {
                            display: block;
                        }

                        .ant-select {
                            width: 100% !important;
                        }
                    }

                    .button-wrapper {
                        margin-top: 23px;
                        padding-left: 0;

                        .aes-btn {
                            &.transparent-btn {
                                height: 47px;
                                font-size: 15px;

                                img {
                                    margin-left: 7px;
                                }
                            }
                        }
                    }

                    .trackingStyle {
                        h5 {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 24px;
                            letter-spacing: -0.01em;
                            color: #333333;

                            span {
                                font-weight: 300;
                            }
                        }
                    }
                }

                .button-row {
                    margin-top: 30px;

                    .left-wrapper,
                    .right-wrapper {
                        padding-left: 0;
                    }

                    .left-wrapper {
                        h3 {
                            font-size: 15px;
                            font-weight: 500;
                            margin-bottom: 4px;
                        }

                        .field {
                            font-size: 15px;
                            opacity: 0.8;
                            font-weight: 300;
                            padding-bottom: 10px;
                            border-bottom: 1px solid #B8B6B2;
                        }
                    }

                    .right-wrapper {
                        align-self: flex-end;

                        .button-wrapper {
                            .aes-btn {
                                height: 48px;
                                min-width: 310px;
                            }
                        }
                    }
                }
            }
        }
    }
}