body {
  font-family: "Sora";;
}

@media (max-width: 1199px) {
  .container {
    padding: 0 30px;
  }
}
@media (max-width: 575px) {
  .container {
    padding: 0 20px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1171px;
    padding: 0 5px;
  }
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #fff;
}
a:hover {
  color: #efefef;
}

.navbar-section {
  height: 101px;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 9999;
  width: 100%;
}

@media (max-width: 425px){
  .navbar-section{
    height: auto;
  }
}

.navbar-section .navbar {
  width: 100%;
  background-color: #fff;
}
.navbar-section .navbar .navbar-container {
  padding: 0 149px 0 140px;
}
@media (max-width: 1366px) {
  .navbar-section .navbar .navbar-container {
    padding: 0 110px 0 110px;
  }
}
@media (max-width: 991px) {
  .navbar-section .navbar .navbar-container {
    padding: 0 30px;
  }
}
@media (max-width: 991px) {
  .navbar-section .navbar .navbar-container .navbar-brand img {
    height: 70px;
  }
}
@media (max-width: 575px) {
  .navbar-section .navbar .navbar-container .navbar-brand img {
    height: 60px;
  }
}
@media (max-width: 425px) {
  .navbar-section .navbar .navbar-container .navbar-brand img {
    height: 40px;
  }
  .navbar-toggler{
    height: 40px;
    width: 40px;
    padding: 0;
  }
}
.navbar-section .navbar .navbar-container .navbar-nav .nav-item {
  margin-right: 23px;
}
.navbar-section .navbar .navbar-container .navbar-nav .nav-item:last-of-type {
  background-color: #89DCB4;
  margin-right: 0;
}
@media (max-width: 991px) {
  .navbar-section .navbar .navbar-container .navbar-nav .nav-item:last-of-type {
    max-width: 200px;
  }
}
.navbar-section .navbar .navbar-container .navbar-nav .nav-item:last-of-type .nav-link {
  padding: 20px 35px;
}
@media (max-width: 991px) {
  .navbar-section .navbar .navbar-container .navbar-nav .nav-item:last-of-type .nav-link {
    max-width: 200px;
    padding: 14px 20px;
  }
}
.navbar-section .navbar .navbar-container .navbar-nav .nav-item .nav-link {
  font-size: 14px;
  color: #292929;
  font-weight: 500;
  padding: 20px 8px;
  text-transform: uppercase;
  font-family: "Sora";;
}

.footer-section {
  min-height: 430px;
  position: relative;
  padding: 90px 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer-section::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: #49381C;
}
.footer-section .footer-content-row {
  position: relative;
  z-index: 999;
}
.footer-section .footer-content-row .footer-content {
  color: #fff;
}
@media (max-width: 767px) {
  .footer-section .footer-content-row .footer-content {
    margin-bottom: 18px;
  }
}
.footer-section .footer-content-row .footer-content .heading {
  font-size: 18px;
  font-weight: 500;
  line-height: 55px;
  text-transform: uppercase;
  font-family: "Sora";;
}
.footer-section .footer-content-row .footer-content p {
  font-size: 15px;
  max-width: 90%;
  margin-bottom: 30px;
  font-weight: 300;
  font-family: "Sora";;
}
.footer-section .footer-content-row .footer-content ul {
  padding-left: 0;
}
.footer-section .footer-content-row .footer-content ul li {
  margin-bottom: 8px;
}
.footer-section .footer-content-row .footer-content ul li a {
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  font-family: "Sora";;
}
.footer-section .footer-content-row .footer-content .icon-wrapper {
  display: flex;
  align-items: center;
}
.footer-section .footer-content-row .footer-content .icon-wrapper .icon {
  background-color: #68573C;
  height: 49px;
  min-width: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  font-family: "Sora";;
}
.footer-section .footer-content-row .footer-content .icon-wrapper .icon:last-of-type {
  margin-right: 0;
}
.footer-section .footer-content-row .footer-content.company-details p {
  margin-bottom: 8px;
  font-weight: 600;
}
.footer-section .footer-content-row .footer-content.company-details p span {
  font-weight: 300;
  margin-left: 2px;
}

.copy-right-section {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #272525;
  font-weight: 300;
}

.home-banner-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 900px;
  position: relative;
  z-index: 11;
  padding-top: 138px;
}
@media (max-width: 1199px) {
  .home-banner-section {
    height: 700px;
    padding-top: 120px;
  }
}
@media (max-width: 575px) {
  .home-banner-section {
    height: 400px;
    padding-top: 40px;
  }
}

@media (max-width: 425px){
  .home-banner-section{
    margin-top: 66px;
  }
}

.home-banner-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(75, 60, 35, 0.7);
}
.home-banner-section .content-section {
  color: #fff;
  position: relative;
  z-index: 99;
  max-width: 720px;
  padding: 0;
}
@media (max-width: 1199px) {
  .home-banner-section .content-section {
    max-width: 560px;
  }
}
.home-banner-section .content-section .title {
  font-size: 60px;
  font-weight: 500;
  line-height: 80px;
  text-transform: uppercase;
  font-family: "Sora";;
}
@media (max-width: 1199px) {
  .home-banner-section .content-section .title {
    font-size: 45px;
    line-height: 60px;
  }
}
@media (max-width: 575px) {
  .home-banner-section .content-section .title {
    font-size: 30px;
    line-height: 45px;
  }
}
@media (max-width: 425px) {
  .home-banner-section .content-section .title {
    font-size: 24px;
    line-height: 38px;
  }
}
.home-banner-section .content-section .sub-title {
  font-size: 22px;
  font-weight: 300;
  line-height: 40px;
  font-family: "Sora";;
}
@media (max-width: 1199px) {
  .home-banner-section .content-section .sub-title {
    font-size: 18px;
    line-height: 34px;
    margin-top: 12px;
   
  }
}
@media (max-width: 575px) {
  .home-banner-section .content-section .sub-title {
    font-size: 14px;
    line-height: 25px;
 
  }
}@media (max-width: 425px) {
  .home-banner-section .content-section .sub-title {
    font-size: 12px;
    line-height: 20px;

  }
}

.about-section {
  position: relative;
  z-index: 111;
  background-color: #fff;
}
.about-section .image-wrapper {
  padding-left: 8px;
  margin-top: -320px;
}
@media (max-width: 1199px) {
  .about-section .image-wrapper {
    margin-top: -200px !important;
  }
  .about-section .image-wrapper img {
    max-height: 500px;
    width: 100%;
  }
}
@media (max-width: 575px) {
  .about-section .image-wrapper {
    margin-top: -100px !important;
  }
}
.about-section .content-row {
  margin: 162px 0;
}
@media (max-width: 1199px) {
  .about-section .content-row {
    margin: 130px 0 !important;
  }
}
@media (max-width: 575px) {
  .about-section .content-row {
    margin: 70px 0 !important ;
  }
}
.about-section .content-row .right-details {
  padding: 0;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1199px) {
  .about-section .content-row .right-details {
    justify-content: center;
    margin-top: 20px;
  }
}
.about-section .content-row .left-details {
  padding: 0;
}
.about-section .content-row .left-details .title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 15px;
  color: #2F2E2B;
  font-family: "Sora";;
}

@media (max-width: 425px) {
  .about-section .content-row .left-details .title {
    font-size: 14px !important;
  }
}

.about-section .content-row .left-details .sub-title {
  text-transform: uppercase;
  font-size: 38px;
  font-weight: 500;
  line-height: 55px;
  max-width: 490px;
  color: #32302E;
  font-family: "Sora";;
}
@media (max-width: 1199px) {
  .about-section .content-row .left-details .sub-title {
    max-width: 90% !important;
    font-size: 35px !important;
  }
}
@media (max-width: 575px) {
  .about-section .content-row .left-details .sub-title {
    max-width: 100% !important;
    font-size: 28px !important;
    line-height: 40px !important;
  }
}
@media (max-width: 425px) {
  .about-section .content-row .left-details .sub-title {
    max-width: 100% !important;
    font-size: 22px !important;
    line-height: 36px !important;
  }
}
.about-section .content-row .left-details .content {
  margin-top: 25px;
  color: #272525;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
}

@media (max-width: 425px){
  .about-section .content-row .left-details .content{
    font-size: 14px !important;
    line-height: 24px !important;
    padding-right: 0 !important;
  }
}

.work-section {
  position: relative;
  z-index: 111;
  background-color: #F5F1EA;
  padding: 124px 0 132px;
}

@media (max-width: 767px){
  .work-section{
    padding: 80px 0;
  }
}


.work-section .image-wrapper {
  padding-left: 8px;
  margin-top: -320px;
}
.work-section .content-row {
  margin: 141px 0 0 0;
}
@media (max-width: 1199px) {
  .work-section .content-row {
    margin: 120px 0;
  }
}
@media (max-width: 575px) {
  .work-section .content-row {
    margin: 70px 0 0;
  }
}
.work-section .content-row .right-details {
  padding: 0;
  display: flex;
  justify-content: flex-end;
}
.work-section .content-row .left-details {
  padding: 0;
}
.work-section .content-row .left-details .title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 15px;
  color: #2F2E2B;
  font-family: "Sora";;

}


.work-section .content-row .left-details .sub-title {
  text-transform: uppercase;
  font-size: 38px;
  font-weight: 500;
  line-height: 55px;
  max-width: 490px;
  color: #32302E;
  font-family: "Sora";;

}
@media (max-width: 1199px) {
  .work-section .content-row .left-details .sub-title {
    max-width: 90% !important;
    font-size: 35px !important;
  }
}
@media (max-width: 767px) {
  .work-section .content-row .left-details .sub-title {
    max-width: 100% !important;
    font-size: 28px !important;
    line-height: 40px !important;
  }
}
@media (max-width: 575px) {
  .work-section .content-row .left-details .sub-title {
    max-width: 100% !important;
    font-size: 22px !important;
    line-height: 34px !important;
  }
}
.work-section .content-row .left-details .content {
  margin-top: 25px;
  color: #272525;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
}

@media (max-width: 425px){
  .work-section .content-row .left-details .content{
    font-size: 14px !important;
    line-height: 24px !important;
    padding-right: 0 !important;
  }
}
.work-section .content-row .video {
  width: 95%;
  padding: 10px 10px;
  width: 95%;
  height: 93%;
  position: relative;
  background-color: #fff;
}
@media (max-width: 1199px) {
  .work-section .content-row .video {
    margin-top: 12px;
    width: 100%;
    height: 96%;
  }
}
@media (max-width: 425px) {
  .work-section .content-row .video {
    height: 88%;
  }
}
.work-section .content-row .video video {
  width: 100%;
  max-height: 100%;
}
.work-section .content-row .video .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.2s;
}
.work-section .content-row .video .play-icon img {
  height: 65px;
  min-width: 65px;
  border-radius: 50%;
}

@media (max-width: 425px){
  .work-section .content-row .video .play-icon img {
  height: 45px;
  min-width: 45px;
  border-radius: 50%;
}
}

.work-section .content-row .video .pause-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.2s;
  opacity: 0;
}
.work-section .content-row .video .pause-icon img {
  height: 65px;
  min-width: 65px;
  border-radius: 50%;
}
.work-section .content-row .video.active .play-icon {
  opacity: 0;
}
.work-section .content-row .video.active .pause-icon {
  opacity: 0;
}
.work-section .content-row .video.active:hover .pause-icon {
  opacity: 1;
}
.work-section h2 {
  text-transform: uppercase;
  color: #2F2E2B;
  font-size: 16px;
  font-weight: 300;
  font-family: "Sora";;

}
.work-section h3 {
  text-transform: uppercase;
  font-size: 38px;
  font-weight: 500;
  line-height: 60px;
  margin-bottom: 48px;
  font-family: "Sora";;

}
@media (max-width: 991px) {
  .work-section .card-row .work-card-wrapper {
    margin-bottom: 15px;
  }
}
.work-section .card-row .work-card-wrapper .work-card {
  border-top: 3px solid #89DCB4;
  background-color: #fff;
  padding: 30px 0 30px 18px;
  min-height: 395px;
}

@media (max-width: 425px){
  .work-section .card-row .work-card-wrapper .work-card {
  padding: 20px 0 20px 12px;
  min-height: 300px;
}
}

.work-section .card-row .work-card-wrapper .work-card .number {
  font-size: 50px;
  line-height: 60px;
  font-weight: 500;
  margin-bottom: 22px;
  color: #32302E;
  font-family: "Sora";;

}

@media (max-width: 425px){
  .work-section .card-row .work-card-wrapper .work-card .number {
  font-size: 40px;
  line-height: 50px;
}
}

.work-section .card-row .work-card-wrapper .work-card .heading-text {
  color: #32302E;
  font-size: 22px;
  font-weight: 500;
  line-height: 60px;
  font-family: "Sora";;

}

@media (max-width: 425px){
  .work-section .card-row .work-card-wrapper .work-card .heading-text {
  font-size: 18px;
  line-height: 30px;
}
}

.work-section .card-row .work-card-wrapper .work-card .content {
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
}

@media (max-width: 425px){
.work-section .card-row .work-card-wrapper .work-card .content {
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
}
}