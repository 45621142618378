.content-section {
    &.deposit-section {
        padding: 0 !important;
        min-width: calc(100% - 330px);
        width: 100%;
        display: flex;
        color: #333333;
        overflow: hidden;
        min-height: unset !important;

        .detail-sidebar-section {
            background-color: #E0DED9;
            max-width: 328px;
            width: 100%;
            padding: 44px 0;
            height: calc(100vh - 80px);
            max-height: 100%;
            overflow-y: auto;

            @media (max-width: 760px) {
                //  position: absolute; 
             display: none;
            }


            .details-header {
                border-bottom: 1px solid #C1BFBA;

                .header-wrapper {
                    display: flex;
                    align-items: center;
                    padding: 0 32px 32px;

                    @media (max-width: 760px) {
                        //  position: absolute; 
                        padding: 0 10px 10px;
                    }

                    .back {
                        cursor: pointer;

                        img {
                            @media (max-width: 760px) {
                                //  position: absolute; 
                                width: 15px;
                                height: 12px;
                            }
                        }
                    }

                    .text {
                        margin-left: 10px;

                        @media (max-width: 760px) {
                            font-size: 12px;
                        }
                    }
                }
            }

            .site-details {
                padding: 23px 32px 0;

                @media (max-width: 760px) {
                    //  position: absolute; 
                    padding: 0 10px 10px;
                }

                .detail-item {
                    color: #333333;
                    margin-bottom: 21px;

                    h3 {
                        font-size: 15px;
                        font-weight: 600;
                        margin-bottom: 8px;

                        @media (max-width: 760px) {
                            //  position: absolute; 
                            font-size: 13px;
                        }
                    }

                    p {
                        font-weight: 300;
                        opacity: 0.8;
                        line-height: 24px;

                        @media (max-width: 760px) {
                            //  position: absolute; 
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .availability-section {
            width: 100%;
            padding: 32px 38px 32px 48px;
            max-height: 100%;
            overflow-y: auto;
            @media (max-width: 776px) {
                padding: 32px 15px 32px 15px;

            }

            .heading-Availability {
                margin-bottom: 6px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                @media (max-width: 776px) {
                    // display: block;
                }

                h1 {
                    margin-bottom: 16px;
                    font-size: 24px;
                    font-weight: 600;
                }
            }

            .load-details-wrapper {
                background-color: #fff;
                border-radius: 6px;

                .load-details-header {
                    border-bottom: 1px solid #DCD9D5;
                    padding: 10px 21px;

                    .load {
                        display: flex;
                        align-items: center;

                        p {
                            margin-bottom: 0;
                            color: #0A5F59;
                            font-weight: 600;

                            &.number {
                                font-size: 40px;
                                letter-spacing: -2px;
                                line-height: 1;
                            }

                            &.text {
                                margin-left: 19px;
                            }
                        }
                    }
                }

                .load-details-body {
                    padding: 25px 24px 31px 30px;

                    .load-range-slider {
                        margin: 17px 0 32px;

                        .indications {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;

                            .indication-item {
                                background-color: #F2F0EC;
                                min-height: 17px;
                                padding: 4px 8px;
                                min-width: 65px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 7px;
                                position: relative;
                                text-transform: uppercase;

                                &::before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    top: 100%;
                                    width: 0;
                                    height: 0;
                                    border: 8px solid transparent;
                                    border-top-color: #F2F0EC;
                                }

                                &.empty-item {
                                    position: relative;
                                    left: 15px;
                                }
                            }
                        }

                        .ant-slider {
                            margin: 13px 0;

                            .ant-slider-rail {
                                background-color: #F2F0EC !important;
                                border-radius: 20px;
                                height: 8px;
                            }

                            .ant-slider-handle {
                                background-color: #0A5F59;
                                border-radius: 20px;
                                height: 13px;
                                width: 3px;

                                &::after {
                                    display: none;
                                }

                                &::before {
                                    display: none;
                                }
                            }

                            .ant-slider-track {
                                background-color: #0A5F59;
                                height: 8px;
                                border-radius: 20px;
                            }
                        }
                    }

                    .load-body-wrapper {
                        .item {
                            border-bottom: 1px solid #DCD9D5;
                            padding-bottom: 21px;
                            margin-bottom: 23px;
                            font-weight: 600;
                            font-size: 15px;

                            span {
                                font-weight: 300;
                                opacity: 0.8;
                            }
                        }

                        .custom-item {
                            p {
                                font-weight: 600;
                            }

                            .custom-field {
                                display: flex;

                                .input-field {
                                    flex-basis: 75%;
                                    width: 100%;

                                    input {
                                        background-color: #F2F0EC;
                                    }
                                }

                                .aes-btn {
                                    min-width: 151px;
                                    flex-basis: 20%;
                                    margin-left: 23px;

                                    @media (max-width: 1366px) {
                                        height: 40px;
                                    }
                                    @media (max-width: 776px) {
                                        min-width: 100px;

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}
// .active>.page-link, .page-link.active{
//     background-color: #0A5F59 !important;
//     border: unset !important;
// }