.warning-modal {
    .modal-dialog {
        transform: none;
        min-width: 536px;
        font-family: "Sora";
    }

    .warning-modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
        position: relative;

        @media (max-width: 1024px) {
            padding: 20px 0;
        }

        .close-icon {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            z-index: 9;
        }

        .warning-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            // width: 200px;
            h3 {
                margin-top: 28px;
                margin-bottom: 0;
                font-family: "Sora";
                font-weight: 600;
                font-size: 24px;
            }

            p {
                align-items: center;
                // margin: 0px 10px;
                text-align: center;
                font-size: 16px;
                font-weight: 300;
                color: #606060;
            }

            .button-wrapper {
                display: flex;
                justify-content: center;
                // margin-top: 10px;
                // margin-bottom: 0;

                @media (max-width: 1366px) {
                    // margin-top: 10px;
                }

                .aes-btn {
                    min-width: 143px;
                }
            }
        }
    }
}
