//variables
$primary: #0a5f59;
$table-head-border: #dcd9d5;
$table-head-bg: rgba(251, 249, 247, 1);

.content {
    font-family: "Sora";
    background-color: #f2f1ec;
    height: 100vh;
    padding: 35px 20px 0px 41px;
    overflow: auto;
    padding-bottom: 100px;

    &.adminNotification {
        height: calc(100vh - 80px);
        //    overflow: hidden;
        .heading {
            color: #333;
            font-family: "Sora";
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 50px;
            /* 208.333% */
            letter-spacing: -0.48px;
        }
        .notification-wrapper {
            display: flex;
            gap: 8px;
            align-items: flex-start;
            margin: 22px 0;
           

            .notification-item {
                border-radius: 4px;
            }

            .bodyScroll {
                max-height: calc(100vh - 20px);
                // top: 0px;

                @media (max-width: 767px) {
                    max-height: calc(100vh - 238px);
                    top: 0;
                }

                @media (max-width: 575px) {
                    max-height: calc(100vh - 230px);
                    top: 0;
                }

                @media (max-width: 402px) {
                    max-height: calc(100vh - 280px);
                    top: 0;
                }
            }

           
            .notification-body {
                background-color: white;
                width: 100%;

                .ant-btn-primary {
                    background-color: white;
                    color: $primary;
                    border-color: $primary;
                }

                .notification-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 12px;

                    .notification-title {
                        h3 {
                            font-size: 16px;
                            font-weight: 600;
                            padding: 14px 0;
                            margin: 0;
                        }
                    }
                

                    .notification-subtitle {
                        color: #626262;
                        font-family: "Sora";
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 20px;
                       text-transform: lowercase;
                    
                      
                    

                        strong {
                            color: #626262;
                            font-family: "Sora";
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            /* 181.818% */
                            text-transform: uppercase;
                        }
                    }
                    .notification-subtitle::first-letter {
                        text-transform: uppercase
                      }
                }

                .notification-body {
                    .notification-table {
                        .ant-table-thead {
                            border: 1px solid $table-head-border;
                            border-radius: 0px;

                            th {
                                background: $table-head-bg;

                                &:first-child,
                                &:last-child {
                                    // important!!!!!
                                    border-radius: 0 !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .toggle {
            width: 241.344px;
            height: 37px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            font-family: "Sora";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: -0.36px;
            margin-bottom: 0;
            cursor: pointer;
        }

        /* Active tab styles */
        .toggle.active {
            background: #fff;
            color: #333;

            .text-color {
                color: #333;
                font-family: "Sora";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                /* 166.667% */
                letter-spacing: -0.36px;
                margin: 0;
            }
        }

        /* Inactive tab styles */
        .toggle {
            border: 1px solid #cbc9c6;
            background: #e0ded9;
            color: #908e89;

            .text-color {
                color: #908e89;
                font-family: "Sora";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                /* 166.667% */
                letter-spacing: -0.36px;
                margin: 0;
            }
        }

        .headerFilter {
            display: flex;
            justify-content: end;
            align-items: baseline;
            gap: 20px;

            @media (max-width: 576px) {
                display: block !important;
            }

            h2 {
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }

        .notification-section {
            display: flex;
            margin-top: 10px;

            .right-section {
                background-color: #ffff;
                border-top: 2px solid #0a5f59;
                border-radius: 4px;
                height: 75px;

                padding: 12px;

                @media (max-width: 576px) {
                    padding: 8px !important;
                }

                .first-text {
                    font-size: 14px;
                    border-bottom: 1px solid grey;
                    margin-bottom: 0px;
                    text-align: center;
                    color: #333333;
                    padding-bottom: 6px;
                    font-weight: 600;
                    white-space: nowrap;
                }

                .sec-text {
                    font-size: 11px;
                    white-space: nowrap;
                    margin-bottom: 0px;
                    text-align: center;
                    color: grey;
                    padding-top: 6px;
                }
            }

            .left-section {
                background-color: #ffff;
                width: 99%;
                margin-left: 11px;
                margin-right: 10px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                height: 75px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 17px;
                padding-left: 21px;
                margin-bottom: 3px;

                @media (max-width: 576px) {
                    padding-right: 10px !important;
                    padding-left: 10px !important;
                }

                .not-text {
                    color: #333333;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0px;

                    @media (max-width: 576px) {
                        font-size: 12px !important;
                    }
                }

                .smal-text {
                    color: #333333;
                    font-size: 12px;
                    font-weight: 300;
                    margin-bottom: 0px;
                }

                .aes-btn {
                    min-width: 98px;

                    @media (max-width: 576px) {
                        min-width: 50px !important;
                    }
                }
            }
        }

        .notification-section {
            &.off-notification {
                .right-section {
                    border: 1px solid #b8b6b2;
                    background-color: #f2f0ec;

                    .first-text {
                        color: #626262;
                    }

                    .not-text {
                        color: #626262;
                    }
                }

                .left-section {
                    border: 1px solid #b8b6b2;
                    background-color: #f2f0ec;

                    .not-text {
                        color: #626262;
                    }

                    .smal-text {
                        color: #626262;
                    }
                }
            }
        }

        .Calendar {
            width: 310px !important;
            position: absolute;
            right: 39px;
            margin-top: 10px;
            border-radius: 10px;
            padding: 1px;

            .ant-picker-calendar-date-content {
                height: unset !important;
            }

            .ant-picker-body th {
                color: #0a5f59;
            }

            .ant-picker-calendar-date {
                border: unset;
            }
        }
    }
}
