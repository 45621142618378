.ant-table-wrapper {
    &.fill-site-table {
        overflow: auto;
        max-height: calc(100vh - 270px);
        padding-right: 19px;
        font-family: "Sora";
        &::-webkit-scrollbar {
            width: 5px;
            background-color: #e0ded9;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            width: 5px;
            background-color: #0a5f59;
            border-radius: 4px;
        }

        .ant-table-content {
            table {
                border-spacing: unset !important;
            }
        }

        .ant-table {
            font-family: "Sora";
            .ant-table-container {
                .ant-table-content {
                    table {
                        .ant-table-thead {
                            .ant-table-cell:nth-child(4) {
                                color: unset;
                            }
                            th {
                                background-color: #fff;
                                font-size: 13px;
                                font-weight: 600;
                                padding: 16px;
                                border: 1px solid #fff;
                                border-bottom-color: #dcd9d5;

                                &::before {
                                    display: none;
                                }

                                &:first-child {
                                    border-radius: 5px 0 0 0;
                                }

                                &:last-child {
                                    border-radius: 0 5px 0 0;
                                }
                            }
                        }

                        .ant-table-tbody {
                            tr {
                                border-color: #a2a2a2;
                                background-color: #fff;

                                &:nth-child(odd) {
                                    background-color: #fbf9f7;
                                }

                                td {
                                    font-weight: 600;
                                    font-size: 15px;
                                    border-bottom: 1px solid #dcd9d5 !important;
                                    color: #333333;

                                    &:first-child {
                                        border-left: unset !important;
                                    }

                                    &:last-child {
                                        border-right: unset !important;
                                    }
                                }
                            }

                            .ant-table-row {
                                &:last-child {
                                    border-radius: 0 0 8px 8px;
                                    outline: unset !important;

                                    td {
                                        border-bottom-color: transparent;
                                    }
                                }
                            }

                            .ant-table-cell {
                                border-radius: unset;
                                border-bottom: 1px solid #a2a2a2;

                                .ant-space-item {
                                    color: #333333;
                                }

                                .role {
                                    p {
                                        color: #333333;
                                    }

                                    .aes-btn {
                                        &.transparent-btn {
                                            border: none !important;
                                        }
                                    }
                                }

                                &.ant-table-cell-row-hover {
                                    background-color: transparent;
                                    border-radius: unset !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.invoices-table {
        .ant-table-cell {
            .role {
                .button-wrapper {
                    display: flex;
                    align-items: center;

                    .aes-btn {
                        &.transparent-btn {
                            font-size: 15px !important;
                            font-weight: 600;
                        }
                    }
                }
            }

            .ant-space {
                &.invoice-number {
                    .ant-space-item {
                        color: #333333;
                        font-weight: 300;
                        opacity: 0.8 !important;
                    }
                }

                &.paid {
                    .ant-space-item {
                        color: #0a5f59 !important;
                        opacity: 0.8 !important;
                    }
                }

                &.waiting {
                    .ant-space-item {
                        color: #fe9a03 !important;
                        opacity: 0.8 !important;
                    }
                }
            }
        }
    }

    &.single-summary-table {
        padding-right: 0;
        overflow: unset;

        &::-webkit-scrollbar {
            display: none;
        }

        .ant-table-content {
            &::-webkit-scrollbar {
                height: 5px;
                background-color: #e0ded9;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb {
                height: 5px;
                background-color: #0a5f59;
                border-radius: 4px;
            }
        }

        .ant-table {
            .ant-table-container {
                .ant-table-content {
                    table {
                        padding-bottom: 20px;
                        .ant-table-thead {
                            th {
                                background-color: #fbf9f7;
                                font-size: 13px;
                                font-weight: 600;
                                padding: 16px;
                                border: 1px solid #fbf9f7;
                                border-bottom-color: #dcd9d5;

                                &::before {
                                    display: none;
                                }

                                &:first-child {
                                    border-radius: 0 0 0 0;
                                }

                                &:last-child {
                                    border-radius: 0 0 0 0;
                                }
                            }
                        }

                        .ant-table-thead,
                        .ant-table-tbody {
                            th,
                            td {
                                &:first-child {
                                    padding-left: 22px;
                                }
                            }
                        }

                        .ant-table-tbody {
                            tr {
                                &:nth-child(odd) {
                                    background-color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
