//variables
$detail-body-bg: #E7E4DE;
$notes-bg: #F2F0EC;
.spinner{
    display: flex;
justify-content: center;
margin-top: 10px;
}
.soil-request-detail-section {
    padding: 25px 16px 0 42px;
    min-height: calc(100%) !important;
    h1{
        color: #333;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 50px; /* 208.333% */
        letter-spacing: -0.48px;
        margin: 0;
    }
    .heading-detail{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        
        h3{
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 600;
        }


    }
    .body-detail{
        background-color: $detail-body-bg;
        padding: 26px 32px 100px 32px;
        border-radius: 10px;
        .contractor-company{
            h3{

                font-size: 18px;
                font-weight: 600;
            }
        }
        .company-info{
            display: flex;
            gap: 29px;
            .address{
                max-width: 312px;
                p{
                    margin-bottom: 0;
                }
            }
            .contact{
                flex-grow: 5;
            }
            .contact, .material-info, .material-analytical-data{
                font-size: 15px;
                font-weight: 300;
                strong{
                    font-weight: 600;
                }
            }
            .material-info{
                flex-grow: 5;
            }
            .material-analytical-data{
                flex-grow: 1;
                .data-title{
                    max-width: 170px;
                }

            }
        }
    }
    .img-bg{
        // img{

            background-color: white;
            padding: 9px 12px;
            border-radius: 4px;
            width: 50px;
            text-align: center;
            margin-top: 10px;

        // }
    }
    .notes-wrapper{
        background-color: $notes-bg;
        border-radius: 4px;
        padding: 25px 32px 25.98px 26.03px;
        margin-top: 35px;
        .notes-heading{
            font-size: 18px;
            font-weight: 600;
        }
        .notes-body{
            font-size: 15px;
            font-weight: 300;
            
        }
    }
    .button-wrapper{
        margin-top: 0px;
        display: flex;
        justify-items: flex-end;
        justify-content: flex-end;
        gap: 14px;
    }
}
.content-section {
    height: auto;
    &.adminContractorDetail-section1 {
        padding: 0 !important;

        @media (max-width: 767px) {
            padding: 0 !important;
        }

        .headingStyle {
            display: flex;
            gap: 10px;
            flex-grow: 1;
            align-items: center;

            @media (max-width: 767px) {
                margin-bottom: 10px;
                flex-basis: 100%;
            }

            .back {
                cursor: pointer;
            }

            h1 {
                font-weight: 600;
                width: 100%;
                font-size: 24px;
                line-height: 50px;
                letter-spacing: -0.02em;
                color: #333333;
                margin: 0px;
            }
        }

        .contentStyle {
            background: #ffffff;
            border-radius: 4px;
            margin-bottom: 12px;
            padding: 22px 20px;

            .heading-name {
                color: #333;
                font-family: "Sora";
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 50px;
                /* 250% */
                letter-spacing: -0.4px;
            }

            .BtnsStyle {
                font-weight: 600;
                font-size: 12px;
                line-height: 30px;
                text-align: center;
                letter-spacing: -0.02em;
                color: #0a5f59;
                width: auto;
                height: 40px;
                background: none;
                border-radius: 4px;
                padding: 0px 24px;
                margin-left: 10px;
            }

            h2 {
                color: #333;
                font-family: "Sora";
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                /* 133.333% */
                letter-spacing: -0.15px;

                span {
                    color: #333;
                    font-family: "Sora";
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;
                    /* 133.333% */
                    letter-spacing: -0.15px;
                }
            }
        }

        .detailTitle {
            color: #333;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 50px;
            margin: 0px;
        }

        .content-container {
            border-radius: 4px;
            background: #FFF;
            margin-top: 15px;
            padding: 15px;

            .dateStyle {
                border-radius: 4px;
                background: #E4F4F3;
                padding: 5px 14px;
                color: #0A5F59;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                width: max-content;
                margin-top: 8px;

                span {
                    font-weight: 300;
                }
            }

            .summaryBtn {
                border-radius: 4px;
                border: 1px solid #0A5F59;
                padding: 5px 24px;
                color: #0A5F59;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                background-color: transparent;
            }

            .detailLabel {
                color: #333;
                
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                margin: 0px;

                span {
                    font-weight: 300;
                    line-height: 24px;
                }
            }
        }

    }
}