.splsah-page-section {
    background-color: #0a5f59;
    font-family: "Sora";
    min-height: 100vh;
    position: relative;
    // background-image: url('../../../assets/images/landing-bg.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media (max-width: 1024px) {
        overflow: auto;
    }

    padding: 20px 0;

    img {
        width: 172px;
        height: 160px;
    }
}
