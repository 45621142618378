.content {
    font-family: "Sora";
    background-color: #f2f1ec;
    height: 100vh;
    padding: 35px 20px 0px 41px;
    overflow: auto;
    padding-bottom: 100px;
  
    &.OwnerDashbord {
        height: calc(100vh - 80px);
        h1 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 0px;
        }
        .headerFilter {
            display: flex;
            justify-content: end;
            align-items: baseline;
            gap: 20px;
            @media (max-width: 576px) {
                display: block !important;
            }
            h2 {
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }
        .notification-section {
            display: flex;
            margin-top: 10px;

            .right-section {
                background-color: #ffff;
                border-top: 2px solid #0a5f59;
                border-radius: 4px;
                height: 75px;

                padding: 12px;
                @media (max-width: 576px) {
                    padding: 8px !important;
                }

                .first-text {
                    font-size: 14px;
                    border-bottom: 1px solid grey;
                    margin-bottom: 0px;
                    text-align: center;
                    color: #333333;
                    padding-bottom: 6px;
                    font-weight: 600;
                    white-space: nowrap;
                }

                .sec-text {
                    font-size: 11px;
                    white-space: nowrap;
                    margin-bottom: 0px;
                    text-align: center;
                    color: grey;
                    padding-top: 6px;
                }
            }

            .left-section {
                background-color: #ffff;
                width: 99%;
                margin-left: 11px;
                margin-right: 10px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                height: 75px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 17px;
                padding-left: 21px;
                margin-bottom: 3px;
                @media (max-width: 576px) {
                    padding-right: 10px !important;
                    padding-left: 10px !important;
                }

                .not-text {
                    color: #333333;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    @media (max-width: 576px) {
                        font-size: 12px !important;
                    }
                }

                .smal-text {
                    color: #333333;
                    font-size: 12px;
                    font-weight: 300;
                    margin-bottom: 0px;
                }

                .aes-btn {
                    min-width: 98px;
                    @media (max-width: 576px) {
                        min-width: 50px !important;
                    }
                }
            }
        }

        .notification-section {
            &.off-notification {
                .right-section {
                    border: 1px solid #b8b6b2;
                    background-color: #f2f0ec;

                    .first-text {
                        color: #626262;
                    }

                    .not-text {
                        color: #626262;
                    }
                }

                .left-section {
                    border: 1px solid #b8b6b2;
                    background-color: #f2f0ec;

                    .not-text {
                        color: #626262;
                    }

                    .smal-text {
                        color: #626262;
                    }
                }
            }
        }

        .Calendar {
            width: 310px !important;
            position: absolute;
            right: 39px;
            margin-top: 10px;
            border-radius: 10px;
            padding: 1px;

            .ant-picker-calendar-date-content {
                height: unset !important;
            }

            .ant-picker-body th {
                color: #0a5f59;
            }

            .ant-picker-calendar-date {
                border: unset;
            }
        }
    }
}
