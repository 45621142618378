.header-section {

    padding: 23px 40px;
    background-color: #fff;
    width: 100%;
    max-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-shadow: 0px, 4px #888888;
    align-items: center;

    .dashboardHeader {
        display: flex;
        justify-content: space-between;

        .dashboard-wrapper {

            width: 100%;

        }

        .wrapper-heading {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
                min-width: 34px;
                min-height: 34px;
            }

            h3 {
                font-size: 16px !important;
                font-weight: 500;
                color: #333333;
                margin-bottom: 0px;
                text-transform: capitalize;

            }

            p {
                font-size: 12PX;
                color: #8C8C8C;
                font-weight: 300;
                margin-bottom: 0px;
                line-height: 1;
                text-transform: capitalize;
            }
        }

        .avatar {
            .profile {
                height: 34px;
                width: 34px;
                border-radius: 50%;
                background-color: #3de9de;
                font-weight: 400;
                margin-right: 12px;
                text-transform: uppercase;
                cursor: pointer;
                color: #333333;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
            }

            .title {
                font-size: 24px;
                font-weight: 500;
                margin: 8px 0 18px;
            }
        }

    }

    .button-wrapper {
        .aes-btn {
            min-width: 86px;
            height: 34px;

            &.transparent-btn {
                font-size: 12px;
            }
        }
    }

}

.inactive-user {
    background: rgba(230, 32, 49, .85);
    border-radius: 6px;
    display: flex;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 200;
    line-height: 15px;
    margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	margin-top: 10px;
    padding: 10px;
}
