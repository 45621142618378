.Forget-modal {
    .modal-content {
        // height: 280px;
    }
    .modal-dialog {
        transform: none;
        max-width: 406px;
    }

    .warning-modal-body {
        padding: 44px 42px;
        position: relative;
        // max-width: 408px;
        min-width: unset;
        min-height: unset;
        .close-icon {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            z-index: 9;
        }

        .warning-wrapper {
            // padding: 0px 30px;
            max-width: 100%;
            // min-width: 408px;
            flex-direction: column;
            // margin: auto;

            // width: 200px;
            h4 {
                // margin-top: 15px;
                font-size: 15px;
                font-family: "Sora";
                font-weight: 400;
            }

            p {
                align-items: center;
                margin: 10px 40px;
                text-align: center;
            }

            .input-wrapper {
                width: 100%;
            }

            input {
                background-color: #f2f0ec;
                width: 100%;
            }

            .button-wrapper {
                // display: flex;
                // justify-content: center;
                .aes-btn {
                    min-width: 323px;
                    // height: 34px;
                    height: 48px;
                    text-transform: unset;
                }
                @media (max-width: 1366px) {
                    margin-top: 5px;
                }

                .aes-btn {
                    width: 100%;
                }
            }
        }

        .last-text {
            span {
                font-size: 15px;
                font-weight: 600;
                margin-top: 30px;
                color: #333333;

                a {
                    color: #0a5f59;
                    cursor: pointer;
                }
            }
        }
    }
}
