// @font-face {
//   font-family: "Sora";;
//   src: url("../css/fonts/fonts.css")
// }

.DepositScreen-main-sction {
  background-color: #fff;
  font-family: "Sora";

  // font-family: "Sora";;
  .deposit-banner-section {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 335px;
    position: relative;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../images/deposit-screen-bg.png");

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .content-section2 {
      color: #fff;
      position: relative;
      z-index: 99;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 744px;
      margin: auto;

      .title {
        font-size: 40px;
        font-weight: 500;
        line-height: 80px;
        text-transform: uppercase;
        text-align: center;
        font-family: "Sora";
      }

      .sub-title {
        font-size: 19px;
        font-weight: 300;
        line-height: 40px;
        font-family: "Sora";

        text-align: center;
      }
    }
  }

  .about-section-deposite {
    position: relative;
    z-index: 111;

    .image-wrapper {
      padding-left: 8px;
      margin-top: -320px;
    }

    .content-row {
      margin: 174px 0;

      .right-details {
        padding: 0;
        display: flex;
        justify-content: flex-end;

        .title {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 300;
          margin-bottom: 15px;
          color: #2f2e2b;
          font-family: "Sora";
        }

        .sub-title {
          text-transform: uppercase;
          font-size: 36px;
          font-weight: 500;
          line-height: 55px;
          max-width: 490px;
          color: #32302e;
          font-family: "Sora";
        }

        .content-para {
          margin-top: 25px;
          color: #272525;
          font-size: 16px;
          font-weight: 300;
          line-height: 30px;
          font-family: "Sora";
        }
      }

      .left-details {
        padding: 0;

        .title {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 300;
          margin-bottom: 15px;
          color: #2f2e2b;
          font-family: "Sora";
        }

        .sub-title {
          text-transform: uppercase;
          font-size: 36px;
          font-weight: 500;
          line-height: 55px;
          max-width: 490px;
          color: #32302e;
          font-family: "Sora";
        }

        .content-para {
          margin-top: 25px;
          color: #272525;
          font-size: 16px;
          font-weight: 300;
          line-height: 30px;
          padding-right: 30px;
          font-family: "Sora";
        }
      }

      .right-details.right-content-details {
        display: unset;
        padding: 0 5px 0 60px;

        .sub-title {
          max-width: 450px;
          font-size: 36px;
        }
      }
    }
  }

  .deposit-section {
    background-color: #f5f1ea;
    padding: 124px 0 132px;

    h2 {
      text-transform: uppercase;
      color: #2f2e2b;
      font-size: 16px;
      font-weight: 300;
      font-family: "Sora";
    }

    h3 {
      text-transform: uppercase;
      font-size: 36px;
      font-weight: 500;
      line-height: 60px;
      margin-bottom: 48px;
      font-family: "Sora";
    }

    .card-row {
      font-family: "Sora";

      .deposit-card-wrapper {
        .deposit-card {
          background-color: #fff;
          padding: 30px;
          min-height: 255px;
          text-align: center;

          .number {
            font-size: 22px;
            line-height: 60px;
            font-weight: 500;
            margin-bottom: 22px;
            color: #32302e;
            font-family: "Sora";
          }

          .heading {
            color: #32302e;
            font-size: 22px;
            font-weight: 500;
            line-height: 60px;
          }

          .content-para {
            font-size: 16px;
            font-weight: 300;
            line-height: 30px;
          }
        }
      }
    }

    .content-row {
      margin-bottom: 128px;

      .right-details {
        padding: 0;
        display: flex;
        justify-content: flex-end;
      }

      .left-details {
        padding: 0;

        .title {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 300;
          margin-bottom: 15px;
          color: #2f2e2b;
          font-family: "Sora";
        }

        .sub-title {
          text-transform: uppercase;
          font-size: 36px;
          font-weight: 500;
          line-height: 55px;
          max-width: 490px;
          color: #32302e;
          font-family: "Sora";
        }

        .content-para {
          margin-top: 25px;
          color: #272525;
          font-size: 16px;
          font-weight: 300;
          line-height: 30px;
          padding-right: 30px;
          font-family: "Sora";
        }
      }
    }
  }

  .application-section {
    background-color: #f5f1ea;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 341px;

    .content-section2 {
      max-width: 486px;
      text-align: center;
      margin: auto;

      h3 {
        font-size: 26px;
        line-height: 42px;
        font-weight: 500;
        margin-bottom: 32px;
        text-transform: uppercase;
        font-family: "Sora";
      }

      .button {
        background-color: #89dcb4;
        padding: 19px 42px;
        font-size: 14px;
        max-width: 248px;
        margin: auto;
        max-height: 60px;
        cursor: pointer;
        text-transform: uppercase;

        a {
          color: #000;
          font-weight: 500;

          &:hover {
            color: #000;
          }
        }
      }

      a {
        .button {
          color: #000;
          font-weight: 500;
        }
      }
    }
  }

  @media (max-width: 425px) {
    .deposit-banner-section {
      margin-top: 66px;

      .content-section2 {
        .title {
          font-size: 24px;
          line-height: 38px;
          font-family: "Sora";
        }

        .sub-title {
          font-size: 12px;
          line-height: 20px;
          font-family: "Sora";
        }
      }
    }

    .about-section-deposite {
      .content-row {
        .left-details {
          .sub-title {
            font-size: 26px !important;
            line-height: 35px;
          }
        }

        .right-details {
          .sub-title {
            font-size: 26px !important;
            line-height: 35px;
            font-family: "Sora";
          }
        }
      }
    }

    .deposit-section {
      h2 {
        font-size: 14px;
        font-family: "Sora";
      }

      h3 {
        font-size: 26px;
        font-weight: 500;
        line-height: 45px;
        margin-bottom: 30px;
        font-family: "Sora";
      }

      .content-row {
        .left-details {
          .sub-title {
            font-size: 22px;
            font-weight: 500;
            line-height: 35px;
            font-family: "Sora";
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .deposit-section {
      .sub-title {
        font-size: 18px;
        line-height: 34px;
        margin-top: 12px;
      }
    }
  }

  @media (max-width: 991px) {
    .deposit-banner-section {
      .content-section2 {
        .title {
          font-size: 35px;
          line-height: 40px;
          font-family: "Sora";
        }

        .sub-title {
          font-size: 16px;
          line-height: 25px;
          font-family: "Sora";
        }
      }
    }
  }

  @media (max-width: 575px) {
    .deposit-banner-section {
      .content-section2 {
        .title {
          font-size: 30px;
          line-height: 45px;
        }

        .sub-title {
          font-size: 14px;
          line-height: 25px;
        }
      }
    }

    .deposit-section {
      .content-row {
        .left-details {
          .content {
            padding-right: 10px;
            font-size: 14px;
          }
        }
      }
    }

    .application-section {
      .content-section2 {
        h3 {
          font-size: 20px;
          line-height: 34px;
          margin-bottom: 22px;
          font-family: "Sora";
        }
      }
    }
  }

  @media (max-width: 767px) {
    .about-section-deposite {
      .content-row {
        .right-details.right-content-details {
          padding-left: 0;
        }
      }
    }

    .deposit-section {
      .card-row {
        .deposit-card-wrapper {
          .deposit-card {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
