.bg-section-services {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-image: url("../images/agronomist-hands.png");
}

.bg-section-services::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}
.services-section {
    position: relative;
    z-index: 1;
}

@media (max-width: 425px) {
    .banner-img {
        margin-top: 66px;
    }
}

.heading-Services {
    font-size: 40px;
    font-weight: 500;
    color: white;
    text-align: center;
    margin-bottom: 10px;
    font-family: "Sora";
    text-transform: uppercase;
}

// .bg-section-services.content {
//     position: relative;
//     z-index: 1;
// }

.main-para {
    margin: 0px;
    font-size: 19px;
    font-weight: 300;
    color: #ffff;
    text-align: center;
    font-family: "Sora";
}

.card-data {
    padding: 151px 0 139px;
    background-color: #f5f1ea;
}

@media (max-width: 991px) {
    .card-data {
        padding: 100px 0;
    }
}

.maindiv {
    display: flex;
    align-items: center;
}

.visitingCard {
    background-color: #fff !important;
    border: 0;
    text-align: center;
    min-height: 438px;
}

.testingSoil {
    height: 144px;
    margin-top: 30px;
}

.testingSoil-heading {
    font-size: 22px;
    font-weight: 500;
    color: #32302e;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
    text-transform: uppercase;
    font-family: "Sora";
}

.testingSoil-para {
    font-size: 16px;
    font-weight: 300;
    color: #272525;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    font-family: "Sora";
}

.about {
    font-weight: 300;
    font-size: 16px;
    color: #2f2e2b;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: "Sora";
}

.detail-container {
    padding-top: 100px !important;
}

@media (max-width: 991px) {
    .detail-container {
        padding: 20px 0px !important;
    }
}

.detail-row {
    margin-bottom: 115px;
}

.about-heading {
    font-weight: 500;
    font-size: 36px;
    color: #32302e;
    text-transform: uppercase;
    font-family: "Sora";
}

@media (max-width: 991px) {
    .about-heading {
        font-size: 28px;
    }
}

.para {
    font-size: 16px;
    font-weight: 300;
    margin-top: 40px;
    color: #272525;
    line-height: 30px;
    letter-spacing: 1%;
    font-family: "Sora";
}

.about-img {
    height: 100%;
    width: 100%;
}

@media (max-width: 991px) {
    .detail-description .about {
        margin-top: 30px;
    }
}
