:root {
    --form-control-color: #0A5F59;
    // --form-control-disabled: #959495;
}

.content-section {

    &.Invoices-Information-section {
        // padding: 25px 40px;
        h1{
            margin-bottom: 0;
            font-size: 24px;
            font-weight: 600;
        }

        .Invoices-Information-content-section {
            padding: 0px 40px;


            .heading {
                display: flex;
                align-items: center;
                margin-bottom: 26px;

               

                .back {
                    margin-right: 14px;
                }
            }

            .invoice-table-list {
                padding: 0 15px;
            }
        }

        .main-row {
            margin-top: 20px;
            margin-bottom: 33px;

            .box-warpper {
                background-color: white;
                height: 159px;
                padding: 24px 34px;
                border-radius: 4px;

                // width: 200px;
                .dight {
                    font-size: 50px;
                    font-weight: 600;
                    color: #0A5F59;
                }

                .text {
                    color: #333333;

                    font-size: 18px;
                    font-weight: 500;
                    margin-top: 10px;

                }
            }
        }

        // .ant-table-wrapper .ant-table .ant-table-container table .ant-table-thead .ant-table-cell:nth-child(4) {
        //     color: unset;
        // }

        .soil-request-table-wrapper {
            margin-bottom: 26px;
            padding-right: 24px;

            .Contractors_name {
                font-weight: 600 !important;
                color: #333333 !important;
            }

            .column {
                font-weight: 300;
                color: #333333;
            }

            table {
                thead {
                    .ant-table-cell {
                        font-weight: 500 !important;
                        font-size: 13px !important;

                    }

                    .ant-table-cell-scrollbar {

                        display: none;
                        background-color: transparent;
                    }
                }

                tbody {
                    tr {
                        td {
                            font-weight: 300 !important;

                            &:last-child {

                                // display: flex;
                                // flex-direction: row-reverse;
                                button {

                                    height: 34px;
                                }

                            }
                        }

                    }
                }
            }
        }
        

        .ant-table-wrapper .ant-table .ant-table-container table .ant-table-thead th:first-child{
            padding: 8px;
        }
        .invoice-info-table .ant-checkbox-indeterminate .ant-checkbox-inner::after{
            background-color: unset !important;
        }
    }

    .filter .button-filter {
        height: 47px;
        padding: 0 21px;
    }

    .row-even {
        background-color: #ffff;
        align-items: center;
    }

    .row-odd {
        background-color: #FBF9F7;
        align-items: center;

    }

    .all-select {
        color: #606060;
        font-size: 12px;
        font-weight: 300;
    }

    .heading-table {
        color: #333333;
        font-size: 13px;
        font-weight: 500;
    }

    .last-heading {
        text-align: end;

        span {
            color: #0A5F59;
            font-size: 15px;
            font-weight: 600;
            cursor: pointer;

        }
    }

    .checkbox-wrapper {

        .check {
            width: 20px;
            height: 20px;

        }
    }
}

// input[type="checkbox"]::before {
//     /* ...existing styles */
//     width: 20px;
//     height: 20px;

//     transform-origin: bottom left;
//     clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
//   }

// input[type="checkbox"] {
//   /* Add if not using autoprefixer */
//   height: 20px;
//   width: 20px;
//   -webkit-appearance: none;
//   /* Remove most all native input styles */
//   appearance: none;
//   /* For iOS < 15 */
//   background-color: var(#0A5F59);
//   /* Not removed via appearance */
//   margin: 0;

//   font: inherit;
//   color: currentColor;
//   width: 1.15em;
//   height: 1.15em;
//   border: 0.15em solid #0A5F59;
//   border-radius: 0.15em;
//   transform: translateY(-0.075em);

//   display: grid;
//   place-content: center;
// }

// input[type="checkbox"]::before {
//   content: "";
//   width: 0.65em;
//   height: 0.65em;
//   clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
//   transform: scale(0);
//   transform-origin: bottom left;
//   transition: 120ms transform ease-in-out;
//   box-shadow: inset 1em 1em var(--form-control-color);
//   /* Windows High Contrast Mode */
//   background-color: #ffff;
// }

// input[type="checkbox"]:checked::before {
//   transform: scale(1);
//   border: 0.15em solid #0A5F59;



// }
// // input:focus {
// //     // box-shadow: unset !important;
// //      border-color: #0A5F59 !important; 
// //     outline: none;
// // }

// input[type="checkbox"]:focus {
// //   outline: max(2px, 0.15px) solid #0A5F59;
//   outline-offset: max(2px, 0.1px);
//   border-color: #0A5F59 !important; 
// }

// input[type="checkbox"]:disabled {
//   --form-control-color: var(--form-control-disabled);

//   color: var(--form-control-disabled);
//   cursor: not-allowed;
// }

.invoice-info-table {
    .ant-checkbox-wrapper {
        &:hover {
            .ant-checkbox {
                &.ant-checkbox-checked {
                    .ant-checkbox-inner {
                        background-color: transparent;
                        border-color: #0A5F59;
                    }
                    &::after{
                        border-color: #0A5F59;
                    }
                }
                .ant-checkbox-inner {
                    border-color: #0A5F59;

                }
            }
        }

        .ant-checkbox {
            &.ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: transparent;
                    border-color: #0A5F59;


                    &::after {
                        transform: rotate(45deg) scale(0.6) translate(-100%, -50%);
                    }
                }
            }

            .ant-checkbox-inner {
                width: 20px;
                height: 20px;

                &::after {
                    top: 31%;
                    width: 8.714285714285714px;
                    border-color: #0A5F59;
                }
            }
        }
    }
    .ant-checkbox-indeterminate{
        .ant-checkbox-inner{
            &::after{
                background-color: #0A5F59;
                width: 18px !important;
                height: 8px;
                top: 50% !important;
                transform: translate(-50%, -50%) scale(0.5);
            }
        }
    }
    .ant-table-thead 
    .ant-table-selection{
        visibility: hidden
    }
}