.ChangePasword {
    background-color: #f5f5f5;
    font-family: "Sora";
    min-height: 100vh;
    position: relative;
    // background-image: url('../../../assets/images/landing-bg.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media (max-width: 1024px) {
        overflow: auto;
    }

    padding: 20px 0;

    .change-box {
        width: 431px;
        // height: 405px;
        background-color: #e7e4de;
        padding: 30px 30px;
        border-radius: 10px;

        .logo {
            display: flex;
            gap: 30px;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            h3 {
                font-size: 26px;
                font-weight: 700;
                color: #333333;
                margin: 0px;
            }

            img {
                width: 53px;
                height: 50px;
            }
        }
    }
    .button-wrapper {
        .aes-btn {
            width: 100%;
            margin-top: 3%;
        }
    }
    .input-wrapper {
        .one-input {
            h4 {
                font-size: 15px;
                font-weight: 500;
            }
        }
        .two-input {
            h4 {
                font-size: 15px;
                font-weight: 500;
                margin-top: 10px;
            }
        }
        .right-icon {
            right: 30px;
        }
    }
}
