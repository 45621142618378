.dateCalender {
    background: #ffffff;
    // box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    // width: 418px;
    // height: 356px;
    padding-right: 20px;
    margin: 0 auto;

    .date-header {
        text-align: center;
    }

    .react-calendar {
        width: 100%;
        height: 100%;
        border: none;
        padding: 20px 30px;

        .react-calendar__navigation {
            button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
            button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
                display: none;
            }

            button.react-calendar__navigation__arrow {
                font-size: 40px;
                color: #333;

                &:hover,
                &:active,
                &:focus {
                    background: transparent;
                }
            }

            button.react-calendar__navigation__label {
                span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #333333;
                }

                &:hover {
                    background: transparent;
                    cursor: initial;
                }
            }
        }

        .react-calendar__viewContainer {
            .react-calendar__year-view__months {
                .react-calendar__tile {
                    &.react-calendar__year-view__months__month {
                        padding: 20px;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 24px;
                        letter-spacing: -0.01em;
                        color: #333333;

                        &:hover {
                            background: #0a5f59;
                            border-radius: 5px;
                            color: #fff;
                        }
                    }

                    &.react-calendar__tile--hasActive {
                        background: #0a5f59;
                        border-radius: 5px;
                        color: #fff;
                    }
                }
            }

            .react-calendar__decade-view {
                .react-calendar__tile {
                    &.react-calendar__decade-view__years__year {
                        padding: 20px;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 24px;
                        letter-spacing: -0.01em;
                        color: #333333;

                        &:hover {
                            background: #0a5f59;
                            border-radius: 5px;
                            color: #fff;
                        }
                    }

                    &.react-calendar__tile--hasActive {
                        background: #0a5f59;
                        border-radius: 5px;
                        color: #fff;
                    }
                }
            }

            .react-calendar__month-view__weekdays {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                text-transform: uppercase;
                color: #0a5f59;

                abbr[title] {
                    text-decoration: none;
                }
            }

            .react-calendar__month-view__days {
                .react-calendar__tile {
                    padding: 9px 3.6667px;
                    margin: 5px 0px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #000000;

                    &.react-calendar__month-view__days__day--neighboringMonth {
                        color: #909090;
                    }

                    &:hover {
                        background: #0a5f59;
                        border-radius: 5px;
                        color: #fff;
                    }

                    &.react-calendar__tile--active {
                        background: #0a5f59;
                        border-radius: 5px;
                        color: #fff;
                    }
                }

                .react-calendar__tile--now {
                    background: transparent;
                }
            }
        }
    }
}

.anticon-calendar {
    svg {
        height: 20px !important;
        width: 20px !important;
    }
}

.select-wrapper {
    display: flex;
    justify-content: space-between;

    .truck-select {
        display: flex;
    }
}

.ChangeStatus {
    .modal-dialog {
        transform: none;
        // min-width: 536px;
        font-family: "Sora";
    }

    .ChangeStatus-modal-body {
        padding: 35px 0;
        position: relative;
        min-height: unset;
        min-width: unset;

        @media (max-width: 1024px) {
            padding: 20px 0;
        }

        .close-icon {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            z-index: 9;
        }

        .ChangeStatus-wrapper {
            // display: flex;
            // flex-direction: column;
            gap: 30px;
            padding: 0px 30px;

            // width: 200px;
            h3 {
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -0.01em;
                color: #262626;
                margin-bottom: 15px;
                margin-top: 10px;
            }

            .checkbox-wrapper2 {
                display: flex;
                align-items: center;
                margin-top: 10px;
                gap: 30px;

                .checkbox-round {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    border-radius: 50%;
                    vertical-align: middle;
                    border: 1px solid #7b7b7b !important;
                    appearance: none;
                    -webkit-appearance: none;
                    outline: none;
                    cursor: pointer;
                    position: relative;
                    padding: 0;
                }

                .checkbox-round:checked {
                    background-image: url("../../assets/images/checks.svg");
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 20px;
                    width: 20px;
                }

                span {
                    margin-left: 8px;
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 26px;
                    letter-spacing: -0.01em;
                    color: #606060;
                }
            }

            p {
                align-items: center;
                // margin: 0px 10px;
                text-align: center;
                font-size: 16px;
                font-weight: 300;
                color: #606060;
            }

            .button-wrapper {
                display: flex;
                // justify-content: center;
                // margin-top: 10px;
                // margin-bottom: 0;

                @media (max-width: 1366px) {
                    // margin-top: 10px;
                }

                .aes-btn {
                    min-width: 143px;
                    background: #0a5f59;
                    border: 1px solid #0a5f59;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 30px;
                    text-align: center;
                    letter-spacing: -0.02em;
                    color: #ffffff;
                    width: 100%;
                }
            }
        }
    }
}

.upload-fileInvoice {
    // max-width: 30%;
    text-align: center;
    // margin: 20px auto;
    margin-right: 20px;
    padding: 20px;
    background: #f4f4f4;
    border-radius: 4px;

    .icon-wrapper {
        margin-top: 10px;

        p {
            margin-bottom: 0;

            a {
                color: #0a5f59;
            }
        }
    }
}

.invoice-Upload-modal {
    .inputWrapper {
        // margin-bottom: 20px;
        padding-right: 25px !important;

        h2 {
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #333333;
        }

        .ant-select {
            .ant-select-selector {
                padding: 14px 16px;
                border: 1px solid #f2f0ec;
                width: 100%;
                height: 48px !important;
                background: #f2f0ec !important;
                border-radius: 4px;
                font-weight: 300;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #7b7b7b;
                flex-direction: row-reverse;

                .ant-select-selection-placeholder {
                    flex-basis: 94%;
                    top: -2px;
                }
            }

            &.ant-select-focused {
                .ant-select-selector {
                    box-shadow: unset !important;
                }
            }

            .ant-select-selection-overflow {
                height: 45px;
                top: -12px;

                .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix) {
                    gap: 20px;

                    .ant-select-selection-item {
                        background: #0a5f59;
                        border: 1px solid #0a5f59;
                        color: #fff;

                        svg {
                            color: #fff;
                            fill: #fff;
                        }
                    }
                }

                .ant-select-selection-overflow-item-suffix {
                    margin-left: 10px;
                }
            }
        }

        .ant-select {
            width: 100%;

            .ant-select-selector {
                // padding: 13px 10px 6px 14px;
                height: 40px;
                border: transparent;

                .ant-select-selection-placeholder {
                    color: #7b7b7b;
                }

                .ant-select-arrow {
                    color: #333333;
                }
            }
        }
    }

    .ant-modal-header {
        margin-bottom: 20px;
    }

    .ant-modal-body {
        max-height: calc(100vh - 255px);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .ant-modal-footer {
        display: flex;
        justify-content: center;

        button {
            min-width: 143px;
            background: #0a5f59;
            border: 1px solid #0a5f59;
            border-radius: 4px;
            font-weight: 600;
            font-size: 12px;
            line-height: 30px;
            text-align: center;
            letter-spacing: -0.02em;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;

            // width: 100%;
            &:hover {
                background: #0a5f59;
                border: 1px solid #0a5f59;
                border-radius: 4px;
                color: #ffffff;
            }
        }
    }
}

.submit-row {
    padding-right: 20px;
}

.ant-form-item .ant-form-item-explain-error {
    margin-top: 7px;
}

.ant-btn-primary {
    background-color: #0a5f59;
}

.ant-btn-primary:not(:disabled):hover {
    background-color: #0d7e76;
}
.ant-picker-range {
    width: 100%;
    height: 49px;
    background-color: rgb(244, 244, 244);
}
.ant-collapse-item {
    width: 100%;
}
.ant-collapse-header {
    flex-direction: row-reverse;
}
